var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { background: "#F5F5F5" } },
    [
      _c(
        "div",
        { staticClass: "bannerBox" },
        [
          _c(
            "el-carousel",
            { attrs: { height: _vm.imgHeight } },
            _vm._l(_vm.bannerList, function (item) {
              return _c("el-carousel-item", { key: item.id }, [
                _c("img", {
                  staticStyle: { width: "100%", height: "auto" },
                  attrs: { src: item.picture, alt: "" },
                }),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "cardBox" }, [
        _c(
          "div",
          { staticClass: "fourCard" },
          _vm._l(_vm.fourCardList, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "fourCardItem",
                on: {
                  click: function ($event) {
                    return _vm.goDetail(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "itemTitle" }, [
                  _c("div", [_vm._v(_vm._s(item.title))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        color: "#757575",
                        "font-weight": "400",
                        width: "100%",
                        "white-space": "nowrap",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.desc) + "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "employ" }, [_vm._v("立刻使用")]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "itemImg",
                  attrs: { src: item.img, alt: "" },
                }),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "threeCard" }, [
          _c("div", { staticClass: "threeCardItem" }, [
            _vm.app_isClient == 0
              ? _c("div", { staticClass: "userTop" }, [
                  _c("img", {
                    staticClass: "userImg",
                    staticStyle: { width: "52px", height: "52px" },
                    attrs: {
                      src: this.$store.state.user.userInfo.logo,
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "userInfo" }, [
                    _c("div", { staticClass: "userPhone" }, [
                      _vm._v(_vm._s(this.$store.state.user.userInfo.mallName)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "userMall" }, [
                      _vm._v(
                        "\n              店铺ID：" +
                          _vm._s(this.$store.state.user.userInfo.mallId) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "userTop" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "userInfo" }, [
                    _c("div", { staticClass: "userPhone" }, [
                      _vm._v(_vm._s(_vm.userName)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "userMall" }, [
                      _vm._v("已授权店铺: " + _vm._s(_vm.shopNum) + "个"),
                    ]),
                  ]),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "mask" }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "100%",
                height: "1px",
                background: "#E7E7E7",
                "margin-top": "20px",
              },
            }),
            _vm._v(" "),
            _vm.businessState && this.$store.state.user.app_isClient === 1
              ? _c("div", { staticClass: "business" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/imgs/积分畅用生效中.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: { width: "86px", height: "58px" },
                    attrs: { src: require("@/assets/imgs/vipqy.png"), alt: "" },
                  }),
                ])
              : _vm.businessState === false &&
                this.$store.state.user.app_isClient === 1
              ? _c("div", { staticClass: "businessNo" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "businessImg",
                    attrs: {
                      src: require("@/assets/imgs/vipno1.png"),
                      alt: "",
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.app_isClient == 0
              ? _c("div", { staticClass: "business" }, [
                  _c("div", { staticClass: "businessTxt" }, [
                    _c("img", {
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        src: require("@/assets/imgs/xzapp.png"),
                        alt: "",
                      },
                      on: { click: _vm.upDownHandle },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: { width: "86px", height: "58px" },
                    attrs: { src: require("@/assets/imgs/vipqy.png"), alt: "" },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "function",
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                },
              },
              [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "functionTxt",
                    staticStyle: {
                      "margin-top": "40px",
                      color: "#838383",
                      cursor: "pointer",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "goShopping",
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handlePayChange()
                          },
                        },
                      },
                      [_vm._v("去开通")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dym",
                staticStyle: {
                  position: "absolute",
                  top: "32px",
                  right: "25px",
                },
              },
              [_c("dynamic")],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "threeCardItem itemB" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "100%",
                height: "1px",
                background: "#E7E7E7",
                "margin-top": "56px",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mask" }),
            _vm._v(" "),
            _c("div", { staticClass: "explain" }, [
              _c("div", { staticClass: "explainTitle" }, [_vm._v("说明文档")]),
              _vm._v(" "),
              _c("div", { staticClass: "explainContent" }, [
                _c(
                  "div",
                  { staticClass: "expItem", on: { click: _vm.toTeach } },
                  [
                    _c("img", {
                      staticStyle: { width: "18px", height: "18px" },
                      attrs: {
                        src: require("@/assets/imgs/excel.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("软件使用指南")]),
                  ]
                ),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _vm._m(5),
              ]),
            ]),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "100%",
                height: "1px",
                background: "#E7E7E7",
              },
            }),
            _vm._v(" "),
            _vm._m(6),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dym",
                staticStyle: {
                  position: "absolute",
                  top: "32px",
                  right: "25px",
                },
              },
              [_c("dynamic")],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "threeCardItem itemB itemC" }, [
            _vm._m(7),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "100%",
                height: "1px",
                background: "#E7E7E7",
                "margin-top": "56px",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mask" }),
            _vm._v(" "),
            _vm._m(8),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dym",
                staticStyle: {
                  position: "absolute",
                  top: "32px",
                  right: "25px",
                },
              },
              [_c("dynamic")],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值",
            visible: _vm.openPay,
            width: "1248px",
            "custom-class": "pay-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openPay = $event
            },
          },
        },
        [
          _c("payment", {
            ref: "payments",
            attrs: { shopData: _vm.shopDetail },
            on: {
              close: function ($event) {
                _vm.openPay = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "userIcon" }, [
      _c("div", { staticClass: "userN" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "businessTxt" }, [
      _c("img", {
        attrs: { src: require("@/assets/imgs/推荐开通积分畅用.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "functionTitleweb" }, [
      _c("div", [_vm._v("限时活动价：68元/月 299元/年")]),
      _vm._v(" "),
      _c("div", { staticClass: "Titleweb" }, [
        _c("div", [_vm._v(" 开通后，每天到账一万积分")]),
        _vm._v(" "),
        _c("div", [_vm._v(" 年卡用户，每日低至8毛钱！")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "course" }, [
      _c("div", { staticClass: "courseTitle" }, [_vm._v("使用教程")]),
      _vm._v(" "),
      _c("div", { staticClass: "courseTxt" }, [_vm._v("快速了解软件基本功能")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "expItem", staticStyle: { "margin-left": "70px" } },
      [
        _c("img", {
          staticStyle: { width: "18px", height: "18px" },
          attrs: { src: require("@/assets/imgs/MP4.svg"), alt: "" },
        }),
        _vm._v(" "),
        _c("span", [_vm._v("机会商品标介绍视频")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "expItem" }, [
      _c("img", {
        staticStyle: { width: "18px", height: "18px" },
        attrs: { src: require("@/assets/imgs/excel.svg"), alt: "" },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("多店搬家使用教程")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "courseBottom" }, [
      _c("img", {
        staticStyle: { width: "27px", height: "27px" },
        attrs: { src: require("@/assets/imgs/tongzhi.svg"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "bottomTxt" }, [
        _vm._v("\n            推荐发布机会商品，获得流量加成！\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "course" }, [
      _c("div", { staticClass: "courseTitle" }, [_vm._v("联系客服")]),
      _vm._v(" "),
      _c("div", { staticClass: "courseTxt" }, [_vm._v("联系客服领取100积分")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact" }, [
      _c("img", {
        staticClass: "kefuimg",
        staticStyle: { width: "200px" },
        attrs: { src: require("@/assets/imgs/22kefu.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "contactTime" }, [
        _c("div", [_vm._v("客服在线时间:9:00-18:00")]),
        _vm._v(" "),
        _c("div", [_vm._v("联系客服领取100积分")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }