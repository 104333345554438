var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sku-content" },
    [
      _vm._l(_vm.specList, function (spec, index) {
        return _c("div", { key: index, staticClass: "sku-box-item" }, [
          _c("div", { staticClass: "option-title-box" }, [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "sku" }, [_vm._v("(主)  规格")]),
              _vm._v(" \n        "),
              _c("span", [_vm._v(_vm._s(Object.keys(spec)[0]))]),
              _vm._v("  \n        "),
              _c("span", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.getListLength(spec)) + "个"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "options-box" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        _vm.AIChangeSkuAll(
                          spec,
                          spec[Object.keys(spec)[0]],
                          index
                        )
                      },
                    },
                  },
                  [_vm._v(" AI一键修改SKU文字\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-refresh" },
                    on: { click: _vm.skuReStore },
                  },
                  [_vm._v("一键还原")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sku-options-box" },
            _vm._l(spec[Object.keys(spec)[0]], function (item, i) {
              return _c(
                "div",
                { key: i, staticClass: "options-item-box" },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      size: "mini",
                      maxlength: "30",
                      "show-word-limit": "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.inputHandle($event, i)
                      },
                    },
                    model: {
                      value: item.value,
                      callback: function ($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { size: "mini", icon: "el-icon-refresh" },
                    on: {
                      click: function ($event) {
                        return _vm.skuSingleRestore(item)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.AIChangeSku(item)
                        },
                      },
                    },
                    [_vm._v("AI")]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-top-box" },
        [
          _c("strong", [_vm._v("SKU数据")]),
          _vm._v("  \n    "),
          _c("span", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.specSkuList.length) + "个"),
          ]),
          _vm._v(" "),
          _c("strong", [_vm._v("    商品参考价：")]),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "250px" },
            attrs: { size: "mini" },
            on: {
              change: function ($event) {
                return _vm.updateMarketPrice($event, _vm.indexS)
              },
            },
            model: {
              value: _vm.marketPrice,
              callback: function ($$v) {
                _vm.marketPrice = $$v
              },
              expression: "marketPrice",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _c(
                "el-table",
                {
                  ref: "myTable",
                  staticStyle: { width: "90%" },
                  attrs: {
                    border: "",
                    "header-cell-style": { background: "#ECEEFF !important" },
                    data: _vm.specSkuList,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "SKU规格" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.spec[0].specName)),
                            ]),
                            _vm._v(" "),
                            scope.row.spec[1]
                              ? _c("span", [
                                  _vm._v(
                                    "/" + _vm._s(scope.row.spec[1].specName)
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "price", label: "单买价", width: "155" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v("单买价")]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSome("price", 4)
                                  },
                                },
                              },
                              [_vm._v("批量改")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "130px" },
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.price,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "price", $$v)
                                },
                                expression: "scope.row.price",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "multiPrice",
                      label: "拼单价",
                      width: "155",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v("拼单价")]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSome("multiPrice", 1)
                                  },
                                },
                              },
                              [_vm._v("批量改")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "130px" },
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.multiPrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "multiPrice", $$v)
                                },
                                expression: "scope.row.multiPrice",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "quantity", width: "155" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v("库存")]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSome("quantity", 2)
                                  },
                                },
                              },
                              [_vm._v("批量改")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "130px" },
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.quantity,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "quantity", $$v)
                                },
                                expression: "scope.row.quantity",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "outSkuSn",
                      label: "商品编码",
                      width: "155",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "130px" },
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.outSkuSn,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "outSkuSn", $$v)
                                },
                                expression: "scope.row.outSkuSn",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "thumbUrl",
                      label: "预览图",
                      align: "center",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  "open-delay": 300,
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    slot: "content",
                                    src: scope.row.thumbUrl,
                                  },
                                  slot: "content",
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "80px",
                                      height: "80px",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "78px",
                                        height: "78px",
                                      },
                                      attrs: { src: scope.row.thumbUrl },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "upLoad",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleImg(scope.row.thumbUrl)
                                  },
                                },
                              },
                              [_vm._v("本地上传\n            ")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              ref: "fileInput",
                              staticStyle: { display: "none" },
                              attrs: { type: "file" },
                              on: { change: _vm.handleFileUpload },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            title: "批量修改",
            visible: _vm.editModalVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editModalVisible = $event
            },
          },
        },
        [
          _c(
            "p",
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.edit1,
                    callback: function ($$v) {
                      _vm.edit1 = $$v
                    },
                    expression: "edit1",
                  },
                },
                [_vm._v("自定义：")]
              ),
              _vm._v("\n      来源价格*\n      "),
              _c("el-input", {
                staticStyle: { width: "110px" },
                attrs: {
                  disabled: _vm.edit1 == 2,
                  size: "mini",
                  placeholder: "请输入倍数",
                },
                model: {
                  value: _vm.myNumBei,
                  callback: function ($$v) {
                    _vm.myNumBei = $$v
                  },
                  expression: "myNumBei",
                },
              }),
              _vm._v("\n      +\n      "),
              _c("el-input", {
                staticStyle: { width: "110px" },
                attrs: {
                  disabled: _vm.edit1 == 2,
                  size: "mini",
                  placeholder: "请输入递增数",
                },
                model: {
                  value: _vm.myNumdiz,
                  callback: function ($$v) {
                    _vm.myNumdiz = $$v
                  },
                  expression: "myNumdiz",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.edit1,
                    callback: function ($$v) {
                      _vm.edit1 = $$v
                    },
                    expression: "edit1",
                  },
                },
                [_vm._v("固 定：")]
              ),
              _vm._v("\n         \n      "),
              _c("el-input", {
                staticStyle: { width: "140px", "margin-top": "20px" },
                attrs: {
                  disabled: _vm.edit1 == 1,
                  size: "mini",
                  placeholder: "请输入数字",
                },
                model: {
                  value: _vm.editVal,
                  callback: function ($$v) {
                    _vm.editVal = $$v
                  },
                  expression: "editVal",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.editTxt == "quantity"
            ? _c(
                "p",
                [
                  _vm._v("\n      SKU库存为0时，将库存设置为：\n      "),
                  _c("el-input", {
                    staticStyle: { width: "140px", "margin-top": "20px" },
                    attrs: { size: "mini", placeholder: "请输入数字" },
                    model: {
                      value: _vm.qcNum,
                      callback: function ($$v) {
                        _vm.qcNum = $$v
                      },
                      expression: "qcNum",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editModalVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editALlSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }