var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "progress-box" },
      [
        _c("el-progress", {
          attrs: {
            type: "dashboard",
            percentage: parseInt(_vm.percentage),
            color: _vm.colors,
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "text-align": "center", "font-size": "14px" } },
          [_vm._v(_vm._s(this.message ? this.message : "等待裂变"))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "error" }, [
          _vm._v(
            _vm._s(_vm.value.message == "操作成功" ? "" : _vm.value.message)
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }