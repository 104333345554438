var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.skuLading,
          expression: "skuLading",
        },
      ],
      attrs: {
        "element-loading-text": _vm.skuLadingTxt,
        "element-loading-background": "rgba(255, 255, 255, 0.5)",
      },
    },
    [
      _c(
        "div",
        { ref: "scrollableElement" },
        [
          _c(
            "div",
            { staticClass: "wrapper-box" },
            [
              _c("el-collapse-transition", [
                !_vm.showSplit
                  ? _c("div", { staticClass: "border-box-top" }, [
                      _c("div", { staticClass: "top-bg" }, [
                        _c(
                          "div",
                          { staticClass: "text-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "txt",
                                staticStyle: { display: "flex" },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-size": "15px",
                                      "line-height": "28px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  1.爆款裂变功能的使用会消耗积分，裂变1个商品将消耗3积分\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "czbtn",
                                    on: { click: _vm.handlePayChange },
                                  },
                                  [
                                    _c("span", [_vm._v("立即充值")]),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticStyle: {
                                        width: "21px",
                                        height: "21px",
                                      },
                                      attrs: {
                                        src: require("@/assets/imgs/jinbi.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-size": "15px",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                2.裂变无法保证百分之百裂变成功，不成功不扣费。\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-size": "15px",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                3.如果勾选多店裂变，裂变到哪个店铺，就扣除哪个店铺的积分，请提前确认店铺积分是否充足。\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "drap-btn",
                                attrs: { size: "mini", round: "" },
                                on: { click: _vm.closeDialog },
                              },
                              [
                                _vm._v(
                                  "\n                返回列表\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "content-box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    "margin-right": "20px",
                                    display: "flex",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "title" }, [
                                    _vm._v("裂变到当前店铺："),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticStyle: { width: "108px" },
                                    attrs: {
                                      disabled: "",
                                      size: "mini",
                                      placeholder: "",
                                    },
                                    model: {
                                      value: _vm.getShop,
                                      callback: function ($$v) {
                                        _vm.getShop = $$v
                                      },
                                      expression: "getShop",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                   \n                  "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "21px",
                                          height: "21px",
                                        },
                                        attrs: {
                                          src: require("@/assets/imgs/jinbi.svg"),
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "#FF3636",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "积分：" + _vm._s(_vm.getIntegral)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePayChange(
                                                _vm.getShop
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("充值\n                    ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    "margin-right": "20px",
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      model: {
                                        value: _vm.splitMore,
                                        callback: function ($$v) {
                                          _vm.splitMore = $$v
                                        },
                                        expression: "splitMore",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v("多店裂变："),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        disabled: !_vm.splitMore,
                                        "popper-append-to-body": false,
                                        placeholder: "请选择",
                                        size: "mini",
                                      },
                                      on: { change: _vm.storeChange },
                                      model: {
                                        value: _vm.valueSelect,
                                        callback: function ($$v) {
                                          _vm.valueSelect = $$v
                                        },
                                        expression: "valueSelect",
                                      },
                                    },
                                    _vm._l(_vm.optionsSelect, function (item) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            disabled: item.onlineStatus == 0,
                                            label: item.mallName,
                                            value: item.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "130px",
                                                overflow: "hidden",
                                                "vertical-align": "middle",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.mallName))]
                                          ),
                                          _vm._v(
                                            "\n                       \n                      "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "100px",
                                                color: "#f01",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      积分:" +
                                                  _vm._s(
                                                    Number(item.integral) +
                                                      Number(item.giftIntegral)
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handlePayChange(
                                                    item.mallName
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "充值\n                      "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(
                                    "\n                   \n                  "
                                  ),
                                  _vm._v(" "),
                                  _vm.valueSelect
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "21px",
                                              height: "21px",
                                            },
                                            attrs: {
                                              src: require("@/assets/imgs/jinbi.svg"),
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                                color: "#FF3636",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "积分：" +
                                                  _vm._s(_vm.storeIntegral)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c("span", { staticClass: "title" }, [
                                    _vm._v("运费模板："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "108px" },
                                      attrs: {
                                        disabled: !_vm.splitMore,
                                        size: "mini",
                                        placeholder: "请选择",
                                      },
                                      on: { change: _vm.getPostTemplate },
                                      model: {
                                        value: _vm.templateVal,
                                        callback: function ($$v) {
                                          _vm.templateVal = $$v
                                        },
                                        expression: "templateVal",
                                      },
                                    },
                                    _vm._l(
                                      _vm.templatePostList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.templateId,
                                          attrs: {
                                            label: item.templateName,
                                            size: "mini",
                                            value: item.templateId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "item" },
                              [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("裂变商品数："),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticStyle: { width: "93px" },
                                  attrs: {
                                    disabled: _vm.showSplit,
                                    size: "mini",
                                    maxlength: "5",
                                    type: "number",
                                    placeholder: "请输入商品数量",
                                  },
                                  on: { change: _vm.inputHandle },
                                  model: {
                                    value: _vm.splitNumber,
                                    callback: function ($$v) {
                                      _vm.splitNumber = $$v
                                    },
                                    expression: "splitNumber",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    attrs: {
                                      disabled: _vm.showSplit,
                                      size: "mini",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: {
                                      click: function () {
                                        this$1.splitNumber = 5
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    5\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    attrs: {
                                      disabled: _vm.showSplit,
                                      size: "mini",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: {
                                      click: function () {
                                        this$1.splitNumber = 10
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    10\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    attrs: {
                                      disabled: _vm.showSplit,
                                      size: "mini",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: {
                                      click: function () {
                                        this$1.splitNumber = 20
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    20\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    attrs: {
                                      disabled: _vm.showSplit,
                                      size: "mini",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: {
                                      click: function () {
                                        this$1.splitNumber = 50
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    50\n                  "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "item" },
                              [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("第一张主图："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      disabled: _vm.showSplit,
                                      label: "1",
                                    },
                                    model: {
                                      value: _vm.mainPicture,
                                      callback: function ($$v) {
                                        _vm.mainPicture = $$v
                                      },
                                      expression: "mainPicture",
                                    },
                                  },
                                  [_vm._v("原主图")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      disabled: _vm.showSplit,
                                      label: "2",
                                    },
                                    model: {
                                      value: _vm.mainPicture,
                                      callback: function ($$v) {
                                        _vm.mainPicture = $$v
                                      },
                                      expression: "mainPicture",
                                    },
                                  },
                                  [_vm._v("自定义换图")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      disabled: _vm.mainPicture == 1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.upLoadImgHandle($event)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    本地上传\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.uploadNum != 0
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          "已选择" +
                                            _vm._s(_vm.uploadNum) +
                                            "个"
                                        ),
                                      ]),
                                      _vm._v(","),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            cursor: "pointer",
                                            color: "#0a6aa1",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showImage($event)
                                            },
                                          },
                                        },
                                        [_vm._v("查看")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c("span", { staticClass: "title" }, [
                                    _vm._v("裂变标题设置："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showSplit,
                                        label: "1",
                                      },
                                      model: {
                                        value: _vm.orPic,
                                        callback: function ($$v) {
                                          _vm.orPic = $$v
                                        },
                                        expression: "orPic",
                                      },
                                    },
                                    [_vm._v("原标题")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showSplit,
                                        label: "2",
                                      },
                                      model: {
                                        value: _vm.orPic,
                                        callback: function ($$v) {
                                          _vm.orPic = $$v
                                        },
                                        expression: "orPic",
                                      },
                                    },
                                    [_vm._v("自定义换标题")]
                                  ),
                                  _vm._v(" "),
                                  _vm.orPic == 2
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: _vm.showSplit,
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.titleBoxVisible = true
                                            },
                                          },
                                        },
                                        [_vm._v("设置\n                  ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "title" }, [
                                    _vm._v("SKU文字设置："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showSplit,
                                        label: "1",
                                      },
                                      model: {
                                        value: _vm.skuText,
                                        callback: function ($$v) {
                                          _vm.skuText = $$v
                                        },
                                        expression: "skuText",
                                      },
                                    },
                                    [_vm._v("原文字")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showSplit,
                                        label: "2",
                                      },
                                      model: {
                                        value: _vm.skuText,
                                        callback: function ($$v) {
                                          _vm.skuText = $$v
                                        },
                                        expression: "skuText",
                                      },
                                    },
                                    [_vm._v("纯净版")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showSplit,
                                        label: "4",
                                      },
                                      model: {
                                        value: _vm.skuText,
                                        callback: function ($$v) {
                                          _vm.skuText = $$v
                                        },
                                        expression: "skuText",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("统一増加")]),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "81px" },
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.skuText != 4,
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.acModel,
                                            callback: function ($$v) {
                                              _vm.acModel = $$v
                                            },
                                            expression: "acModel",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "最前面",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "最后面",
                                              value: "2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          size: "mini",
                                          disabled: _vm.skuText != 4,
                                        },
                                        model: {
                                          value: _vm.skuFixTitle,
                                          callback: function ($$v) {
                                            _vm.skuFixTitle = $$v
                                          },
                                          expression: "skuFixTitle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "item" },
                              [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("SKU价格设置："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      disabled: _vm.showSplit,
                                      label: "1",
                                    },
                                    model: {
                                      value: _vm.skuPrice,
                                      callback: function ($$v) {
                                        _vm.skuPrice = $$v
                                      },
                                      expression: "skuPrice",
                                    },
                                  },
                                  [_vm._v("原价格")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      disabled: _vm.showSplit,
                                      label: "2",
                                    },
                                    model: {
                                      value: _vm.skuPrice,
                                      callback: function ($$v) {
                                        _vm.skuPrice = $$v
                                      },
                                      expression: "skuPrice",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(" 原价增减随机值，范围"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.skuPrice != 2,
                                      },
                                      model: {
                                        value: _vm.skuPriceVal1,
                                        callback: function ($$v) {
                                          _vm.skuPriceVal1 = $$v
                                        },
                                        expression: "skuPriceVal1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#9e9e9e" } },
                                      [_vm._v("——")]
                                    ),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.skuPrice != 2,
                                      },
                                      model: {
                                        value: _vm.skuPriceVal2,
                                        callback: function ($$v) {
                                          _vm.skuPriceVal2 = $$v
                                        },
                                        expression: "skuPriceVal2",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "title" }, [
                                    _vm._v("商品发布模式："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showSplit,
                                        label: "1",
                                      },
                                      on: { input: _vm.radioInput },
                                      model: {
                                        value: _vm.releaseMode,
                                        callback: function ($$v) {
                                          _vm.releaseMode = $$v
                                        },
                                        expression: "releaseMode",
                                      },
                                    },
                                    [_vm._v("立即上架\n                  ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showSplit,
                                        label: "2",
                                      },
                                      on: { input: _vm.radioInput },
                                      model: {
                                        value: _vm.releaseMode,
                                        callback: function ($$v) {
                                          _vm.releaseMode = $$v
                                        },
                                        expression: "releaseMode",
                                      },
                                    },
                                    [_vm._v("放入已下架\n                  ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showSplit,
                                        label: "3",
                                      },
                                      on: { input: _vm.radioInput },
                                      model: {
                                        value: _vm.releaseMode,
                                        callback: function ($$v) {
                                          _vm.releaseMode = $$v
                                        },
                                        expression: "releaseMode",
                                      },
                                    },
                                    [_vm._v("放入草稿箱\n                  ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "limitTxt",
                                      staticStyle: {
                                        height: "16px",
                                        "line-height": "16px",
                                      },
                                    },
                                    [_vm._v("温馨提示")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "predict" }, [
                                    _c("span", [
                                      _vm._v(
                                        "预计消耗：" +
                                          _vm._s(_vm.splitNumber * 3) +
                                          "积分"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticStyle: {
                                        width: "21px",
                                        height: "21px",
                                        "vertical-align": "middle",
                                      },
                                      attrs: {
                                        src: require("@/assets/imgs/jinbi.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bg-box scroll-container",
                  staticStyle: {
                    "max-height": "calc(100vh -  120px)",
                    overflow: "auto",
                  },
                  style: { top: _vm.showSplit ? "0" : "-180px" },
                  on: { scroll: _vm.handleScroll },
                },
                [
                  _c("div", { staticClass: "goods-info-box" }, [
                    _c("div", { staticClass: "info-g" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("\n              产品信息\n            "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "info-box" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { type: "text" },
                              on: { click: _vm.checkAll },
                            },
                            [_vm._v("全选")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              staticStyle: {
                                "margin-right": "20px",
                                "font-size": "14px",
                              },
                              attrs: { type: "text" },
                              on: { click: _vm.checkAllNo },
                            },
                            [_vm._v("反选\n              ")]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "img-g",
                            attrs: {
                              src: _vm.detailInfo.goodsPicture,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "info-txt" }, [
                            _c("div", { staticClass: "txt-til" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.detailInfo.goodsName) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "g-id" }, [
                              _vm._v(
                                "商品ID:\n                  " +
                                  _vm._s(_vm.detailInfo.goodsId) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "price" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("价格")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "price-box" }, [
                        _c("p", { staticStyle: { color: "#FF003E" } }, [
                          _vm._v(
                            "拼单价：" +
                              _vm._s(_vm.detailInfo.minGroupPrice) +
                              "-" +
                              _vm._s(_vm.detailInfo.maxGroupPrice) +
                              "￥"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "单买价：" +
                              _vm._s(_vm.detailInfo.minSinglePurchasePrice) +
                              "-" +
                              _vm._s(_vm.detailInfo.maxSinglePurchasePrice) +
                              "￥"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticStyle: { color: "#ff8a00" } }, [
                          _vm._v(
                            "参考价：" +
                              _vm._s(_vm.detailInfo.marketPrice) +
                              "￥"
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "sku-info" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("SKU")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sku-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.detailInfo.sku) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre-sale" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("是否预售")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sku-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.detailInfo.presaleWay) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre-sale" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("操作")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "sku-item" },
                        [
                          !_vm.showSplit
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    round: "",
                                  },
                                  on: { click: _vm.preViewHandle },
                                },
                                [_vm._v("开始裂变\n              ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showSplit && !_vm.splitbtnloading
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: { click: _vm.canClePreview },
                                },
                                [
                                  _vm._v(
                                    "\n                取消裂变\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.splitbtnloading
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: { click: _vm.cancleSplit },
                                },
                                [_vm._v("取消铺货")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showSplit
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#9EDAFF",
                                    color: "#454545",
                                    border: "none",
                                  },
                                  attrs: {
                                    size: "mini",
                                    type: "info",
                                    loading: _vm.splitbtnloading,
                                  },
                                  on: { click: _vm.startSplit },
                                },
                                [
                                  _vm._v(
                                    "\n                开始裂变\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.progressStatus
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "margin-left": "15px",
                          },
                        },
                        [
                          _c("el-progress", {
                            staticStyle: { width: "657px" },
                            attrs: {
                              percentage: parseInt(
                                (_vm.nowNum / _vm.splitNumber) * 100
                              ),
                              color: "#EA91AB",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "#EA91AB" } }, [
                            _vm._v(
                              "\n            (" +
                                _vm._s(_vm.nowNum) +
                                "/" +
                                _vm._s(_vm.splitNumber) +
                                ") 共" +
                                _vm._s(_vm.splitNumber) +
                                "条已完成" +
                                _vm._s(_vm.nowNum) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isShowPreview
                    ? _c("splitEditInfo", { ref: "splitEditInfos" })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("uploadImages", {
            ref: "uploadImageRef",
            attrs: { show: _vm.uploadImageVisible },
            on: {
              "update:show": function ($event) {
                _vm.uploadImageVisible = $event
              },
            },
          }),
          _vm._v(" "),
          _c("showDialog", {
            ref: "showDialogRef",
            attrs: { show: _vm.isShowDialog },
            on: {
              "update:show": function ($event) {
                _vm.isShowDialog = $event
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "查看",
                width: "1000px",
                visible: _vm.titleBoxVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.titleBoxVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "up-img-box" },
                [
                  _c("div", { staticClass: "oldTitle" }, [
                    _c("span", [_vm._v("原商品标题:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.detailInfo.goodsName))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "incTitle" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.upLoadTitle($event)
                            },
                          },
                        },
                        [_vm._v("导入标题")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.getClipboardContent },
                        },
                        [_vm._v("粘贴自定义标题")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.handleCopyTitle },
                        },
                        [_vm._v("粘贴原标题")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        ref: "fileInputs",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", accept: ".xlsx, .xls" },
                        on: { change: _vm.handleFileUpload },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "titleSet" }, [
                            _vm._v("处理标题设置"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              ref: "formSet",
                              attrs: {
                                model: _vm.formSet,
                                "label-width": "100px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "删除内容" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.formSet.delContent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formSet,
                                            "delContent",
                                            $$v
                                          )
                                        },
                                        expression: "formSet.delContent",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("删除英文"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("删除数字"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 3 } }, [
                                        _vm._v("删除空格"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 4 } }, [
                                        _vm._v("删除括号及内容"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "删除标题关键词",
                                    "label-width": "110px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isShowDelTitle = true
                                            },
                                          },
                                        },
                                        [_vm._v("设置")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "24px",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("当标题超过60个字符时删除"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "140px" },
                                              model: {
                                                value:
                                                  _vm.formSet.delTitleKeyWord,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formSet,
                                                    "delTitleKeyWord",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formSet.delTitleKeyWord",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "最前面字符",
                                                  value: 1,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "最后面字符",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "替换标题关键词",
                                    "label-width": "110px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isShowReplaceTitle = true
                                            },
                                          },
                                        },
                                        [_vm._v("设置")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "24px",
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("标题前缀：")]),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            staticStyle: { width: "140px" },
                                            model: {
                                              value:
                                                _vm.formSet.replaceTitleKeyWord,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formSet,
                                                  "replaceTitleKeyWord",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formSet.replaceTitleKeyWord",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#FF003E" },
                                            },
                                            [
                                              _vm._v(
                                                "注意：添加文字后，标题字数是否会超过平台限制"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "标题后缀" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "140px" },
                                    model: {
                                      value: _vm.formSet.titleSuffix,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formSet,
                                          "titleSuffix",
                                          $$v
                                        )
                                      },
                                      expression: "formSet.titleSuffix",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.handleDisposeTitle },
                                    },
                                    [_vm._v("一键处理标题")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      ref: "multipleTitleTable",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.excelData, height: "320" },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "#",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "val", label: "商品标题" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "90%" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入",
                                        clearable: "",
                                        maxlength: "60",
                                      },
                                      model: {
                                        value: scope.row.val,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "val", $$v)
                                        },
                                        expression: "scope.row.val",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color:
                                            _vm.handleTitleLength(
                                              scope.row.val
                                            ) > 60
                                              ? "#FF003E"
                                              : "#333",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.handleTitleLength(scope.row.val)
                                          ) + "/60"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handledelTitle(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("移除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "dialog-footer" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handleSaveTitle },
                          },
                          [_vm._v("导入标题列表")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.handleBatchDelTitle },
                          },
                          [_vm._v("批量移除")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.handleAddTitleChange },
                          },
                          [_vm._v("增加行标题")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "删除标题关键字",
                visible: _vm.isShowDelTitle,
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isShowDelTitle = $event
                },
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { maxRows: 9, minRows: 9 },
                  placeholder: "请输入内容",
                },
                model: {
                  value: _vm.formSet.delTitleText,
                  callback: function ($$v) {
                    _vm.$set(_vm.formSet, "delTitleText", $$v)
                  },
                  expression: "formSet.delTitleText",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.isShowDelTitle = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "替换关键字（格式：替换词-关键词）",
                visible: _vm.isShowReplaceTitle,
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isShowReplaceTitle = $event
                },
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { maxRows: 9, minRows: 9 },
                  placeholder: "请输入内容",
                },
                model: {
                  value: _vm.formSet.replaceTitleText,
                  callback: function ($$v) {
                    _vm.$set(_vm.formSet, "replaceTitleText", $$v)
                  },
                  expression: "formSet.replaceTitleText",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.isShowReplaceTitle = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("imageEdit", {
        ref: "imgEditRef",
        attrs: { show: _vm.imgEditVisible },
        on: {
          "update:show": function ($event) {
            _vm.imgEditVisible = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "边框库", visible: _vm.borderOpen, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.borderOpen = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px", background: "#e6f7ff" } },
            [
              _vm._v(
                "\n      说明：可多选边框，多选的边框会随机应用到裂变商品中。一个商品只能有一种边框效果。\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: {
                "margin-top": "20px",
                height: "500px",
                "overflow-y": "auto",
              },
              attrs: { gutter: 20 },
            },
            _vm._l(_vm.localImages, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "200px",
                        width: "200px",
                        position: "relative",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectBorder(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item.imgUrl, alt: "" },
                      }),
                      _vm._v(" "),
                      item.flag
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                height: "20px",
                                width: "20px",
                                background: "#409EFF",
                                "text-align": "center",
                                "line-height": "20px",
                                position: "absolute",
                                top: "0",
                                right: "0",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-check",
                                staticStyle: { color: "#fff" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleBorderCancel } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.borderOpen = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "已选择边框",
            visible: _vm.borderSelectOpen,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.borderSelectOpen = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                "margin-top": "20px",
                height: "500px",
                "overflow-y": "auto",
              },
              attrs: { gutter: 20 },
            },
            _vm._l(_vm.borderImgList, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "200px",
                        width: "200px",
                        position: "relative",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item, alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "20px",
                            width: "20px",
                            "border-radius": "50%",
                            background: "#F56C6C",
                            "text-align": "center",
                            "line-height": "20px",
                            position: "absolute",
                            top: "0",
                            right: "0",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDeleteBorder(index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-close",
                            staticStyle: { color: "#fff", cursor: "pointer" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.borderSelectOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.borderSelectOpen = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值",
            visible: _vm.openPay,
            width: "1248px",
            "custom-class": "pay-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openPay = $event
            },
          },
        },
        [
          _c("payment", {
            ref: "payments",
            attrs: { shopData: _vm.shopDetail },
            on: {
              close: function ($event) {
                _vm.openPay = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showGotop
        ? _c(
            "a",
            {
              staticStyle: {
                "z-index": "999999",
                position: "fixed",
                right: "30px",
                bottom: "50px",
              },
              attrs: { href: "#topAnchor" },
            },
            [
              _c("img", {
                staticStyle: { width: "60px", height: "60px" },
                attrs: { src: require("@/assets/imgs/goTop.png"), alt: "" },
                on: { click: _vm.scrollToTop },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }