var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c("keep-alive", [
            _c(
              "div",
              [
                _vm.$route.meta.title !== "主页"
                  ? _c(
                      "div",
                      { staticStyle: { margin: "5px 15px 0 15px" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              height: "35px",
                              "line-height": "35px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "18px",
                                  "font-weight": "bold",
                                },
                              },
                              [_vm._v(_vm._s(_vm.title))]
                            ),
                            _vm._v(" "),
                            _c("div", { staticStyle: { width: "10px" } }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              { attrs: { size: "mini", type: "text" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                    },
                                    on: { click: _vm.handlePayChange_2 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          color: "red",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$route.meta.tip))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          color: "blue",
                                        },
                                      },
                                      [_vm._v("点击抢购 !!!")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticStyle: {
                            "border-bottom": "1px solid #cccccc",
                            "margin-top": "10px",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-dialog",
                          {
                            attrs: {
                              title: "充值",
                              visible: _vm.openPay_2,
                              width: "1248px",
                              "custom-class": "pay-dialog",
                            },
                            on: {
                              "update:visible": function ($event) {
                                _vm.openPay_2 = $event
                              },
                            },
                          },
                          [
                            _c("payment", {
                              ref: "payments",
                              attrs: { shopData: _vm.shopData },
                              on: {
                                close: function ($event) {
                                  _vm.openPay = false
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("router-view", { key: _vm.key }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }