"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pddGoodsInformationGet = pddGoodsInformationGet;
exports.pddLogisticsAddressGet = pddLogisticsAddressGet;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * 获取拼多多官方的省市区
 * @param prams
 */
function pddLogisticsAddressGet(data) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/userMall/pddLogisticsAddressGet',
    method: 'POST',
    data: data
  });
}
function pddGoodsInformationGet(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/pddGoodsInformationGet',
    method: 'post',
    data: data
  });
}