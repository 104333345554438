var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "div",
        { staticClass: "conetn-box" },
        [
          _c("span", { on: { click: _vm.queryHandle } }, [_vm._m(0)]),
          _vm._v(" "),
          _vm.$store.state.user.app_isClient == 1
            ? _c(
                "span",
                {
                  staticClass: "btn-teach",
                  staticStyle: { padding: "5px 8px" },
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "20px",
                      height: "20px",
                      "vertical-align": "middle",
                    },
                    attrs: {
                      slot: "reference",
                      src: require("../../assets/imgs/tip-logo.gif"),
                      alt: "",
                    },
                    slot: "reference",
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#f01",
                        display: "inline-block",
                        "margin-right": "10px",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = true
                        },
                      },
                    },
                    [_vm._v("联系客服可领取免费积分卡")]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-popover",
            {
              attrs: { placement: "top-start", width: "230", trigger: "click" },
            },
            [
              _c("img", {
                staticStyle: { width: "200px", height: "200px" },
                attrs: {
                  src: require("../../assets/imgs/kefu1.png"),
                  alt: "描述文字",
                },
              }),
              _vm._v(" "),
              _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                _c("span", { staticClass: "btn-teach" }, [
                  _c("img", {
                    staticStyle: {
                      width: "12px",
                      height: "12px",
                      "margin-top": "-4px",
                      "margin-right": "0px",
                      "vertical-align": "middle",
                    },
                    attrs: {
                      src: require("../../assets/imgs/kefu-pic.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("\n            联系客服"),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-popover",
            {
              attrs: { placement: "top-start", width: "230", trigger: "click" },
            },
            [
              _c("img", {
                staticStyle: { width: "200px", height: "200px" },
                attrs: {
                  src: require("../../assets/imgs/gzh.jpeg"),
                  alt: "描述文字",
                },
              }),
              _vm._v(" "),
              _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn-teach",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "18px",
                        height: "18px",
                        "margin-top": "-2px",
                        "margin-right": "3px",
                        "vertical-align": "middle",
                      },
                      attrs: {
                        slot: "reference",
                        src: require("../../assets/imgs/gzh-pic.png"),
                        alt: "",
                      },
                      slot: "reference",
                    }),
                    _vm._v("公众号"),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-popover",
            {
              attrs: { placement: "top-start", width: "425", trigger: "click" },
            },
            [
              _c("img", {
                staticStyle: { width: "400px" },
                attrs: { src: _vm.postUrl, alt: "描述文字" },
              }),
              _vm._v(" "),
              _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn-teach",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "18px",
                        height: "18px",
                        "margin-top": "-2px",
                        "vertical-align": "middle",
                      },
                      attrs: {
                        src: require("../../assets/imgs/tea-pic.png"),
                        alt: "",
                      },
                    }),
                    _vm._v("\n           功能介绍"),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.$store.state.user.app_isClient == 0
            ? _c("span", [
                _c(
                  "span",
                  { staticClass: "btn-teach", on: { click: _vm.downApp } },
                  [_vm._v("下载客户端")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.user.app_isClient == 0
            ? _c(
                "el-popover",
                {
                  attrs: {
                    placement: "top-start",
                    width: "260",
                    trigger: "click",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-right": "5px" },
                        model: {
                          value: _vm.$store.state.user.userInfo.authCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$store.state.user.userInfo,
                              "authCode",
                              $$v
                            )
                          },
                          expression: "$store.state.user.userInfo.authCode",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "50px",
                            padding: "2px 8px",
                            border: "1px solid #f3f3f3",
                            "font-size": "12px",
                          },
                          on: { click: _vm.copyCode },
                        },
                        [_vm._v("复制")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "btn-teach",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_vm._v("获取授权码")]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.user.app_isClient == 0
            ? _c("div", { staticClass: "score" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/imgs/金币 (1).png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("当前积分：" + _vm._s(_vm.score))]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "right",
                    on: {
                      click: function ($event) {
                        return _vm.handlePayChange_2()
                      },
                    },
                  },
                  [
                    _vm._v("\n          立刻充值积分\n          "),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "user" }, [
            _c("div", { staticClass: "left" }, [
              _c("img", {
                staticClass: "userN",
                attrs: {
                  src:
                    _vm.$store.state.user.app_isClient == 0
                      ? _vm.$store.state.user.userInfo.logo
                      : require("../../assets/imgs/boom.png"),
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _vm.$store.state.user.app_isClient == 1
                ? _c(
                    "div",
                    { staticClass: "top", staticStyle: { display: "flex" } },
                    [
                      _c("span", [
                        _vm._v(
                          "用户：" + _vm._s(this.$store.getters.name) + " "
                        ),
                      ]),
                    ]
                  )
                : _c("div", { staticClass: "top" }, [
                    _vm._v(
                      "当前店铺：" +
                        _vm._s(_vm.$store.state.user.userInfo.mallName) +
                        " "
                    ),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom" }, [
                _vm.$store.state.user.app_isClient == 0
                  ? _c("div", { staticClass: "left" }, [
                      _vm._v("到期时间:" + _vm._s(_vm.time) + "天"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "right",
                    on: {
                      click: function ($event) {
                        _vm.$store.state.user.app_isClient == 0
                          ? _vm.buystore()
                          : _vm.handlePayChange_2()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/imgs/再次购买.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$store.state.user.app_isClient == 0
                            ? "立即续费"
                            : "充值积分"
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-popover",
            {
              attrs: { placement: "bottom", width: "160" },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c("p", [_vm._v("确定要退出登录吗？")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right", margin: "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "text" },
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.outLogin },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "outLogin",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "退出登录",
                        placement: "bottom-start",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/微信图片_20250111150706.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值",
            visible: _vm.openPay,
            width: "1094px",
            "custom-class": "pay-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openPay = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "formPay", attrs: { model: _vm.formPay } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "积分充值" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { input: _vm.getPayType },
                      model: {
                        value: _vm.formPay.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formPay, "payType", $$v)
                        },
                        expression: "formPay.payType",
                      },
                    },
                    _vm._l(_vm.integralList, function (item, index) {
                      return _c(
                        "el-radio",
                        { key: item.id, attrs: { label: item.denomination } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.denomination * 100) +
                              "积分\n            "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "充值金额" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { disabled: "", size: "mini" },
                    model: {
                      value: _vm.formPay.payType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formPay, "payType", $$v)
                      },
                      expression: "formPay.payType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用兑换码" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px", "margin-right": "50px" },
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.formPay.exchangeCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formPay, "exchangeCode", $$v)
                      },
                      expression: "formPay.exchangeCode",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.exchangeCodeHandle },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("div", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("付款详情"),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("充值店铺：")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "150px", "margin-right": "50px" },
                    attrs: { disabled: "", size: "mini" },
                    model: {
                      value: _vm.formPay.payShopName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formPay, "payShopName", $$v)
                      },
                      expression: "formPay.payShopName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "payImg" }, [
            _c("img", {
              staticStyle: {
                width: "184px",
                height: "200px",
                "background-color": "#eee",
              },
              attrs: { src: _vm.aliPayCode, alt: "" },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "payText",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "align-content": "center",
                },
              },
              [
                _c("img", {
                  staticStyle: {
                    width: "50px",
                    height: "50px",
                    "margin-right": "10px",
                  },
                  attrs: {
                    src: require("../../assets/imgs/scan-pic.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("div", [
                  _c("div", [_vm._v("打开手机支付宝")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("扫一扫继续付款")]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值",
            visible: _vm.openPay_2,
            width: "1248px",
            "custom-class": "pay-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openPay_2 = $event
            },
          },
        },
        [
          _c("payment", {
            ref: "payments",
            attrs: { shopData: _vm.shopData },
            on: {
              close: function ($event) {
                _vm.openPay_2 = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialog" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "提示",
                visible: _vm.dialogVisible,
                "append-to-body": "",
                width: "340px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tag-top",
                  staticStyle: { height: "50px" },
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("el-tag", { attrs: { type: "danger" } }, [
                    _vm._v("微信扫一扫"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticStyle: { "margin-top": "8px" } },
                    [
                      _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v("联系客服领取100使用积分"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("img", {
                staticStyle: { width: "300px", height: "300px" },
                attrs: { src: require("@/assets/imgs/22kefu.png") },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "btn-teach" }, [
      _c("img", {
        staticStyle: {
          width: "16px",
          height: "16px",
          "vertical-align": "middle",
          "margin-top": "-3px",
        },
        attrs: { src: require("../../assets/imgs/icon-query.png"), alt: "" },
      }),
      _vm._v("\n              扣费说明\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }