"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    value: {
      type: Object,
      default: {
        stepActive: 1,
        stepActiveStatus: false
      }
    }
  },
  data: function data() {
    return {
      percentage: 0,
      colors: [{
        color: '#c0c4cc',
        percentage: 20
      }, {
        color: '#e6a23c',
        percentage: 40
      }, {
        color: '#5cb87a',
        percentage: 60
      }, {
        color: '#1989fa',
        percentage: 80
      }, {
        color: '#5cb87a',
        percentage: 100
      }],
      message: '',
      errorMessage: '',
      timer: null,
      timer2: null
    };
  },
  watch: {
    value: {
      handler: function handler(newValue, oldValue) {
        var _this = this;
        clearInterval(this.timer);
        clearInterval(this.timer2);
        if (newValue.stepActive == 1) {
          this.percentage = 0;
          this.message = '等待裂变';
        } else if (newValue.stepActive == 2) {
          this.message = '正在裂变中...';
          this.timer = setInterval(function () {
            if (_this.percentage < 90) {
              _this.percentage += 10;
            } else if (_this.percentage >= 90 && _this.percentage < 98) {
              _this.percentage += 2;
            } else {
              _this.percentage += 0.2;
            }
            if (_this.percentage >= 100) {
              _this.percentage = 100;
              clearInterval(_this.timer);
            }
          }, 100);
        } else {
          this.$nextTick(function () {
            _this.percentage = 100;
          });
          if (newValue.stepActiveStatus) {
            this.message = '裂变成功';
          } else {
            this.message = '裂变失败';
            this.colors[4].color = '#f56c6c';
          }
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {}
};