var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "scrollContainer",
        staticClass: "scroll-container",
        on: { mouseenter: _vm.startScroll, mouseleave: _vm.reverseScroll },
      },
      [
        _c("img", {
          staticClass: "scroll-image",
          attrs: { src: _vm.longImageUrl, alt: "" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }