"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      longImageUrl: require('@/assets/imgs/web-light.png'),
      // 替换为你的长图URL
      scrollInterval: null,
      reverseAnimationFrame: null,
      scrollSpeed: 130,
      // 滚动速度，单位像素/步
      originalTop: 0 // 记录原始顶部位置
    };
  },
  mounted: function mounted() {
    // 初始化时确保图片位置正确
    var image = this.$refs.scrollContainer.querySelector('.scroll-image');
    if (image) {
      image.style.top = "".concat(this.originalTop, "px");
    }
  },
  methods: {
    startScroll: function startScroll() {
      var _this = this;
      this.stopScroll(); // 确保没有正在进行的倒放动画

      this.scrollInterval = setInterval(function () {
        var container = _this.$refs.scrollContainer;
        var image = container.querySelector('.scroll-image');
        if (image) {
          var currentTop = parseFloat(image.style.top || '0');
          var newTop = currentTop - _this.scrollSpeed;

          // 如果图片已经完全滚动完毕，则重置位置
          if (newTop <= -image.offsetHeight + container.offsetHeight) {
            // image.style.top = `${this.originalTop}px`;
            // newTop = this.originalTop;
          } else {
            image.style.top = "".concat(newTop, "px");
          }
        }
      }, 40);
    },
    reverseScroll: function reverseScroll() {
      var _this2 = this;
      this.stopScroll(); // 停止任何前进滚动

      var container = this.$refs.scrollContainer;
      var image = container.querySelector('.scroll-image');
      if (!image) return;
      var reverseInterval = setInterval(function () {
        var currentTop = parseFloat(image.style.top || '0');
        if (currentTop < _this2.originalTop) {
          var newTop = currentTop + _this2.scrollSpeed;
          image.style.top = "".concat(newTop, "px");
        } else {
          image.style.top = "".concat(_this2.originalTop, "px"); // 确保最终位置准确无误
          clearInterval(reverseInterval); // 清除定时器
        }
      }, 40);

      // 将定时器存储起来以便稍后可以清除
      this.reverseInterval = reverseInterval;
    },
    stopScroll: function stopScroll() {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
      clearInterval(this.reverseInterval); // 清除倒放动画的定时器
      this.reverseInterval = null;
    }
  }
};