"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pddGoodSpecIdGetApi = pddGoodSpecIdGetApi;
exports.skuApi = skuApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * 获取爆款分类
 */
function skuApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/ai/sku',
    method: 'POST',
    data: data
  });
}

/**
 * 生成自定义的规格
 */
function pddGoodSpecIdGetApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/pddGoodSpecIdGet',
    method: 'POST',
    data: data
  });
}