var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "split-accord-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品ID:【" + _vm.goodsId + "】裂变记录",
            width: "80%",
            visible: _vm.show,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                data: _vm.data,
                height: 500,
                "header-cell-style": { background: "#eef1f6" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: "center",
                  label: "序号",
                  width: "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "fromMallName",
                  label: "裂变店鋪",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "targetPddMallName",
                  label: "裂变到店鋪",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "allCount",
                  label: "裂变总数量",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "successCount",
                  label: "裂变成功数量",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "failCount",
                  label: "裂变失败数量",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "createTime",
                  label: "创建时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "finishTime",
                  label: "完成时间",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": 10,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }