var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.user.app_isClient == 1
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          staticClass: "divBox",
        },
        [
          _c(
            "div",
            { staticClass: "_container" },
            [
              !_vm.splitDataVisible
                ? [
                    _c(
                      "div",
                      { staticClass: "tab-box" },
                      [
                        _c(
                          "el-tabs",
                          {
                            on: { "tab-click": _vm.handleClick },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          [
                            _c("el-tab-pane", {
                              attrs: { label: "全部", name: "2" },
                            }),
                            _vm._v(" "),
                            _c("el-tab-pane", {
                              attrs: { label: "在售中", name: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-tab-pane", {
                              attrs: { label: "已下架", name: "0" },
                            }),
                            _vm._v(" "),
                            _c("el-tab-pane", {
                              attrs: { label: "功能介绍", name: "3" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == 3,
                            expression: "activeName == 3",
                          },
                        ],
                        staticClass: "funIntroduction",
                      },
                      [
                        _c("h3", [_vm._v("什么是机会商品？")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "1、“机会商品”是行业内TOP商家精选的潜力商品，对这些商品，平台会进行流量扶持，帮助销量提升。"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "2、拥有机会商品标，就可以获得流量加持，销量提升50%以上，更容易打上“新品标”，收获拼多多App优质流量。"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("h3", [_vm._v("如何发布机会商品？")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("1、飞鸟系统，支持自动发布，智能匹配同款！"),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n            2、无论裂变多少条链接，任意勾选1条机会商品即可，飞鸟系统会自动覆盖所有链接，智能匹配相似产品，裂变成功后，必得机会商品标！"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("h3", [_vm._v("收费说明：")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "1、联系客服，领取积分卡，免费开通机会商品裂变/搬家权限！"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("2、不成功不扣费，成功后收取5积分/条。"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "btn-box",
                            staticStyle: {
                              "margin-top": "20px",
                              "margin-bottom": "20px",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", round: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickBtn("contactServer")
                                  },
                                },
                              },
                              [_vm._v("去使用")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-image", {
                          staticStyle: {
                            margin: "20px 0 210px",
                            border: "#2279E3 1px solid",
                          },
                          attrs: {
                            src: require("@/assets/imgs/图片_059_06.png"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName != 3,
                            expression: "activeName != 3",
                          },
                        ],
                        staticClass: "search-box",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "search-item" },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("店铺："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticClass: "custom-select",
                                staticStyle: { width: "200px" },
                                attrs: {
                                  "popper-append-to-body": false,
                                  placeholder: "请选择",
                                  size: "mini",
                                },
                                on: { change: _vm.storeChange },
                                model: {
                                  value: _vm.valueSelect,
                                  callback: function ($$v) {
                                    _vm.valueSelect = $$v
                                  },
                                  expression: "valueSelect",
                                },
                              },
                              _vm._l(_vm.optionsSelect, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.id,
                                    attrs: {
                                      disabled:
                                        item.opportunityGoodsIsUsable == 0,
                                      label: item.mallName,
                                      value: item.id,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { float: "left" } },
                                      [_vm._v(_vm._s(item.mallName))]
                                    ),
                                    _vm._v(" "),
                                    item.opportunityGoodsIsUsable != 1
                                      ? [
                                          item.opportunityGoodsIsUsable == 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "13px",
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [_vm._v("已开通")]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#f01",
                                                    "font-size": "13px",
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [_vm._v("未开通")]
                                              ),
                                          _vm._v(" "),
                                          _c(
                                            "el-link",
                                            {
                                              staticStyle: {
                                                "margin-top": "-4px",
                                              },
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openStore(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "去开通\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _vm.$store.state.user.app_isClient == 1
                              ? _c("i", {
                                  class: [
                                    _vm.rotate
                                      ? "el-icon-refresh go"
                                      : "el-icon-refresh",
                                  ],
                                  staticStyle: {
                                    margin: "0 5px 0 5px",
                                    "font-size": "18px",
                                    position: "relative",
                                    cursor: "pointer",
                                  },
                                  on: { click: _vm.getCloudsShopList },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "search-item" },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("商品ID："),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: {
                                size: "mini",
                                placeholder: "请输入商品id",
                              },
                              model: {
                                value: _vm.goodsId,
                                callback: function ($$v) {
                                  _vm.goodsId = $$v
                                },
                                expression: "goodsId",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "search-item" },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("发布时间："),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "280px" },
                              attrs: {
                                "value-format": "timestamp",
                                type: "daterange",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                size: "mini",
                              },
                              model: {
                                value: _vm.postTime,
                                callback: function ($$v) {
                                  _vm.postTime = $$v
                                },
                                expression: "postTime",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "btn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchHandle },
                              },
                              [_vm._v("搜索")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName != 3,
                            expression: "activeName != 3",
                          },
                        ],
                        staticStyle: {
                          color: "#f01",
                          "font-size": "14px",
                          cursor: "pointer",
                          "margin-top": "10px",
                        },
                        on: {
                          click: function ($event) {
                            _vm.centerDialogVisible = true
                          },
                        },
                      },
                      [_vm._v("联系客服免费开通此功能，领取积分兑换码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "扫码联系客服",
                          visible: _vm.centerDialogVisible,
                          width: "30%",
                          center: "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.centerDialogVisible = $event
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "50%" },
                              attrs: {
                                src: "https://91feiniao.oss-cn-hangzhou.aliyuncs.com/file/feiNiaoProductManagement/icon/ddb13cb5e0044044fa9c25fb2425a8f.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName != 3,
                            expression: "activeName != 3",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-top": "20px",
                            },
                            attrs: {
                              data: _vm.tableData,
                              height: "calc(100vh - 320px)",
                              "header-cell-style": {
                                background: "#eef1f6",
                                fontWeight: "700",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "商品信息",
                                align: "center",
                                width: "300",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", { staticClass: "info-box" }, [
                                          _c("img", {
                                            staticClass: "img-g",
                                            attrs: {
                                              src: scope.row.goodsPicture,
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "info-txt" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "txt-til" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        scope.row.goodsName
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "g-id" },
                                                [
                                                  _vm._v(
                                                    "商品ID:\n                      " +
                                                      _vm._s(
                                                        scope.row.goodsId
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3103611238
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "price",
                                align: "center",
                                width: "250",
                                label: "价格",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", { staticClass: "price" }, [
                                          _c(
                                            "div",
                                            { staticClass: "price-box" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#FF003E",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "拼单价：￥" +
                                                      _vm._s(
                                                        scope.row.minGroupPrice
                                                      ) +
                                                      "-" +
                                                      _vm._s(
                                                        scope.row.maxGroupPrice
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  "单买价：￥" +
                                                    _vm._s(
                                                      scope.row
                                                        .minSinglePurchasePrice
                                                    ) +
                                                    "-" +
                                                    _vm._s(
                                                      scope.row
                                                        .maxSinglePurchasePrice
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#ff8a00",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "参考价：￥" +
                                                      _vm._s(
                                                        scope.row.marketPrice
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4178502570
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "SKU", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.sku) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3591354480
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "状态", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.isOnsale == 0
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "danger" } },
                                              [_vm._v("已下架")]
                                            )
                                          : _c(
                                              "el-tag",
                                              { attrs: { type: "success" } },
                                              [_vm._v("上架中")]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1614311178
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goodsQuantity",
                                label: "库存",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "presaleWay",
                                label: "是否预售",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "操作",
                                width: "220",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goodsSplit(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("AI裂变")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openSplitDiolag(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "裂变记录\n                  "
                                            ),
                                            scope.row.fissionAddAmount > 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        scope.row
                                                          .fissionAddAmount >=
                                                          99
                                                          ? "99+"
                                                          : scope.row
                                                              .fissionAddAmount
                                                      ) +
                                                      ")"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2374830822
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName != 3,
                            expression: "activeName != 3",
                          },
                        ],
                        staticClass: "pagetation-box",
                      },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "current-page": _vm.currentPage,
                            "page-size": _vm.limit,
                            layout: "total, prev, pager, next",
                            total: _vm.total,
                          },
                          on: {
                            "update:currentPage": function ($event) {
                              _vm.currentPage = $event
                            },
                            "update:current-page": function ($event) {
                              _vm.currentPage = $event
                            },
                            "current-change": _vm.handleCurrentChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("splitAccordDialog", {
                      ref: "splitAccordDialog",
                      attrs: { show: _vm.splitAccordVisible },
                      on: {
                        "update:show": function ($event) {
                          _vm.splitAccordVisible = $event
                        },
                      },
                    }),
                  ]
                : _c("splitDataDialog", {
                    ref: "splitData",
                    attrs: { show: _vm.splitDataVisible },
                    on: {
                      "update:show": function ($event) {
                        _vm.splitDataVisible = $event
                      },
                    },
                  }),
            ],
            2
          ),
        ]
      )
    : _c(
        "div",
        { staticClass: "_container c-bg", on: { click: _vm.downloadHandle } },
        [_c("div", { staticClass: "left-bg-box" })]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }