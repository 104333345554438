var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "divBox",
    },
    [
      _c(
        "div",
        { staticClass: "_container" },
        [
          !_vm.splitDataVisible
            ? [
                _c(
                  "div",
                  { staticClass: "tab-box" },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleClick },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "全部", name: "2" },
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: { label: "在售中", name: "1" },
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: { label: "已下架", name: "0" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "search-box" }, [
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _vm.$store.state.user.app_isClient == 1
                        ? [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("店铺："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticClass: "custom-select",
                                staticStyle: { width: "200px" },
                                attrs: {
                                  "popper-append-to-body": false,
                                  placeholder: "请选择",
                                  size: "mini",
                                },
                                on: { change: _vm.storeChange },
                                model: {
                                  value: _vm.valueSelect,
                                  callback: function ($$v) {
                                    _vm.valueSelect = $$v
                                  },
                                  expression: "valueSelect",
                                },
                              },
                              _vm._l(_vm.optionsSelect, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.id,
                                    attrs: {
                                      disabled: item.onlineStatus == 0,
                                      label: item.mallName,
                                      value: item.id,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { float: "left" } },
                                      [_vm._v(_vm._s(item.mallName))]
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("i", {
                              class: [
                                _vm.rotate
                                  ? "el-icon-refresh go"
                                  : "el-icon-refresh",
                              ],
                              staticStyle: {
                                margin: "0 5px 0 5px",
                                "font-size": "18px",
                                position: "relative",
                                cursor: "pointer",
                              },
                              on: { click: _vm.getCloudsShopList },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("商品ID："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { size: "mini", placeholder: "请输入商品id" },
                        model: {
                          value: _vm.goodsId,
                          callback: function ($$v) {
                            _vm.goodsId = $$v
                          },
                          expression: "goodsId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("发布时间："),
                      ]),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "280px" },
                        attrs: {
                          "value-format": "timestamp",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          size: "mini",
                        },
                        model: {
                          value: _vm.postTime,
                          callback: function ($$v) {
                            _vm.postTime = $$v
                          },
                          expression: "postTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchHandle },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", "margin-top": "20px" },
                        attrs: {
                          data: _vm.tableData,
                          height: "calc(100vh - 280px)",
                          "header-cell-style": {
                            background: "#eef1f6",
                            fontWeight: "700",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "商品信息",
                            align: "center",
                            width: "300",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "info-box" }, [
                                      _c("img", {
                                        staticClass: "img-g",
                                        attrs: {
                                          src: scope.row.goodsPicture,
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "info-txt" }, [
                                        _c("div", { staticClass: "txt-til" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(scope.row.goodsName) +
                                              "\n                  "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "g-id" }, [
                                          _vm._v(
                                            "商品ID:\n                    " +
                                              _vm._s(scope.row.goodsId) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2637910374
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "price",
                            align: "center",
                            width: "250",
                            label: "价格",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "price" }, [
                                      _c("div", { staticClass: "price-box" }, [
                                        _c(
                                          "p",
                                          { staticStyle: { color: "#FF003E" } },
                                          [
                                            _vm._v(
                                              "拼单价：" +
                                                _vm._s(
                                                  scope.row.minGroupPrice
                                                ) +
                                                "-" +
                                                _vm._s(
                                                  scope.row.maxGroupPrice
                                                ) +
                                                "￥"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(
                                            "单买价：" +
                                              _vm._s(
                                                scope.row.minSinglePurchasePrice
                                              ) +
                                              "-" +
                                              _vm._s(
                                                scope.row.maxSinglePurchasePrice
                                              ) +
                                              "￥"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          { staticStyle: { color: "#ff8a00" } },
                                          [
                                            _vm._v(
                                              "参考价：" +
                                                _vm._s(scope.row.marketPrice) +
                                                "￥"
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            686479969
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "SKU", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "12px" } },
                                      [_vm._v(_vm._s(scope.row.sku))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1124871096
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "状态", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.isOnsale == 0
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "danger" } },
                                          [_vm._v("已下架")]
                                        )
                                      : _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("上架中")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1614311178
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsQuantity",
                            label: "库存",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "presaleWay",
                            label: "是否预售",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "操作",
                            width: "240",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goodsSplit(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("AI裂变")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openSplitDiolag(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v("裂变记录\n                "),
                                        scope.row.fissionAddAmount > 0
                                          ? _c("span", [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    scope.row
                                                      .fissionAddAmount >= 99
                                                      ? "99+"
                                                      : scope.row
                                                          .fissionAddAmount
                                                  ) +
                                                  ")"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2521584486
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pagetation-box" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-size": _vm.limit,
                        layout: "total, prev, pager, next",
                        total: _vm.total,
                      },
                      on: {
                        "update:currentPage": function ($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function ($event) {
                          _vm.currentPage = $event
                        },
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("splitAccordDialog", {
                  ref: "splitAccordDialog",
                  attrs: { show: _vm.splitAccordVisible },
                  on: {
                    "update:show": function ($event) {
                      _vm.splitAccordVisible = $event
                    },
                  },
                }),
              ]
            : _c("splitDataDialog", {
                ref: "splitData",
                attrs: { show: _vm.splitDataVisible },
                on: {
                  "update:show": function ($event) {
                    _vm.splitDataVisible = $event
                  },
                },
              }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }