var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-upload-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "", width: "50%", visible: _vm.show },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              { staticClass: "custom-title" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c("el-tab-pane", {
                      attrs: { label: "本地上传", name: "1" },
                    }),
                    _vm._v(" "),
                    _vm.$parent.editModalType
                      ? _c("el-tab-pane", {
                          attrs: { label: "图片链接", name: "2" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.activeName == 1
            ? _c(
                "div",
                {
                  staticClass: "up-img-box",
                  staticStyle: { "margin-bottom": "30px" },
                },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "#",
                        multiple: "",
                        "list-type": "picture-card",
                        "on-preview": _vm.handlePictureCardPreview,
                        "on-remove": _vm.handleRemove,
                        "file-list": _vm.fileList,
                        "before-upload": _vm.beforeUpload,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: { visible: _vm.dialogVisible },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "link-box" },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 6, maxRows: 6 },
                      placeholder: "直接输入图片链接，一行一个",
                      resize: "none",
                    },
                    model: {
                      value: _vm.linkVal,
                      callback: function ($$v) {
                        _vm.linkVal = $$v
                      },
                      expression: "linkVal",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn",
                      staticStyle: {
                        "text-align": "right",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", disabled: !_vm.linkVal },
                          on: { click: _vm.generateImg },
                        },
                        [_vm._v("生成")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.linkImgList.length > 0
                    ? _c("div", { staticClass: "img-show-box" }, [
                        _vm._v("\n        图片预览：\n        "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "img-box" },
                          _vm._l(_vm.linkImgList, function (item) {
                            return _c(
                              "el-tooltip",
                              {
                                key: item,
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  "open-delay": 300,
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "img-pic",
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    slot: "content",
                                    src: item,
                                    alt: "",
                                  },
                                  slot: "content",
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "img-pic",
                                  attrs: { src: item, alt: "" },
                                }),
                              ]
                            )
                          }),
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
          _vm._v(" "),
          _c("span", [
            _vm._v("图片要求：宽高比例为1:1,且宽高均大于480px,大小3M内"),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.ensureHandle } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }