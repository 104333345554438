var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "split-accord-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "任务日志", width: "80%", visible: _vm.show },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "task-log-box" },
            _vm._l(_vm.taskDetailListData, function (item) {
              return _c("div", { staticClass: "box-content" }, [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: item.goodsImage, alt: "" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "right-content" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(
                      "\n            " + _vm._s(item.goodsName) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("商品ID: " + _vm._s(item.newGoodsId))]),
                  _vm._v("\n              \n          "),
                  item.status == 0 ? _c("span", [_vm._v("待处理")]) : _vm._e(),
                  _vm._v(" "),
                  item.status == -1
                    ? _c("span", [_vm._v("处理失败")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#f01" } }, [
                    _vm._v(_vm._s(item.remark)),
                  ]),
                  _vm._v(" "),
                  item.status == 1 ? _c("span", [_vm._v("已完成")]) : _vm._e(),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPageRecord,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.limitRecord,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalRecord,
            },
            on: {
              "size-change": _vm.handleSizeRecordChange,
              "current-change": _vm.handleCurrentRecordChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }