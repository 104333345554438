var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "userTop" }, [
      _c("div", { staticClass: "userTopLeft" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "userContent" }, [
          _c("div", { staticClass: "userName" }, [
            _c("span", [_vm._v("用户：")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.userName))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "shopName" },
            [
              _c("span", [_vm._v("店铺：")]),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  staticStyle: { color: "#fff" },
                  attrs: { trigger: "click" },
                  on: { command: _vm.handleShopChange },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "el-dropdown-link",
                      staticStyle: { cursor: "pointer" },
                    },
                    [
                      _vm._v("\n              " + _vm._s(_vm.payShopName)),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.optionsSelect, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        { key: index, attrs: { command: item } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.mallName) +
                              "\n              "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("img", {
        staticStyle: { width: "330px", height: "66px", "margin-right": "10%" },
        attrs: { src: require("@/assets/imgs/5c959eda57cb5.png"), alt: "" },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tabs" }, [
      _c(
        "div",
        {
          staticClass: "tabLeft",
          class: { activeLeft: _vm.activeIndex === 0 },
          on: {
            click: function ($event) {
              return _vm.handleTabChange(0)
            },
          },
        },
        [
          _vm.activeIndex === 0
            ? _c("img", {
                staticStyle: { border: "1px solid transparent !important" },
                attrs: { src: require("@/assets/imgs/jifen2.png"), alt: "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex === 1 || _vm.activeIndex == 2
            ? _c("img", {
                attrs: { src: require("@/assets/imgs/jinbi1.png"), alt: "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex === 1 || _vm.activeIndex == 2
            ? _c("span", { staticStyle: { "margin-left": "17px" } }, [
                _vm._v("充值积分"),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tabMiddle",
          class: { activeMiddle: _vm.activeIndex === 2 },
          on: {
            click: function ($event) {
              return _vm.handleTabChange(2)
            },
          },
        },
        [
          _vm.activeIndex === 2
            ? _c("img", {
                attrs: { src: require("@/assets/imgs/jifen1.png"), alt: "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex === 0 || _vm.activeIndex == 1
            ? _c("img", {
                attrs: { src: require("@/assets/imgs/会员积分.png"), alt: "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex === 0 || _vm.activeIndex == 1
            ? _c("span", { staticStyle: { "margin-left": "17px" } }, [
                _vm._v("积分畅用"),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "piece" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tabRight",
          class: { activeRight: _vm.activeIndex === 1 },
          on: {
            click: function ($event) {
              return _vm.handleTabChange(1)
            },
          },
        },
        [
          _vm.activeIndex === 1
            ? _c("img", {
                attrs: { src: require("@/assets/imgs/jifen3.png"), alt: "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex === 0 || _vm.activeIndex == 2
            ? _c("img", {
                staticStyle: {
                  width: "40px",
                  height: "30px",
                  "margin-top": "-14px",
                },
                attrs: { src: require("@/assets/imgs/duihuanma.png"), alt: "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex === 0 || _vm.activeIndex == 2
            ? _c("span", { staticStyle: { "margin-left": "17px" } }, [
                _vm._v("使用兑换码"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.activeIndex === 2
      ? _c("div", { staticClass: "useSmooth" }, [
          _c("div", { staticClass: "payLeft" }, [
            _c("div", {}, [
              _c("div", { staticClass: "monthly" }, [
                _c("div", { staticClass: "monthlyContent" }, [
                  _c("div", { staticClass: "conContent" }, [
                    _c(
                      "div",
                      [
                        _vm._v("\n                1、联系客服，赠送100积分 "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#3D99FF",
                              "font-size": "13px",
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                _vm.dialogVisible = true
                              },
                            },
                          },
                          [_vm._v(" 立即联系")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                2、如果您在试用期期间订购的其他正式版，需要等试用版时间使用结束后，正式版本的权益会自动到账。\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                3、需要先订购软件，才能进入飞鸟使用软件功能，请注意，积分包月/包年，不包含服务市场的软件订购费用。\n              "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "totalBox" },
              _vm._l(_vm.mealList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "total",
                    class: { totalActive: _vm.mealIndex == index },
                    on: {
                      click: function ($event) {
                        return _vm.handleMealChange(item, index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "totalItem" }, [
                      _c("div", { staticClass: "yuan" }, [
                        _c("img", {
                          staticStyle: { height: "16px", width: "16px" },
                          attrs: {
                            src: require("@/assets/imgs/红包.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "18px",
                              color: "#1B1B1B",
                              "font-weight": "700",
                            },
                          },
                          [_vm._v("积分包" + _vm._s(item.remark.slice(-1)))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "totalContent" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "18px",
                              color: "#FDBA24",
                              "font-weight": "700",
                              "margin-top": "20px",
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fk" }),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-check iconChoose" }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "xshd",
                      attrs: {
                        src: require("@/assets/imgs/xshd.png"),
                        alt: "",
                      },
                    }),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm._m(1),
          ]),
          _vm._v(" "),
          _c("div", {
            staticStyle: {
              height: "470px",
              width: "1px",
              background: "#EBEBEB",
              margin: "0 10px",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "payRight" }, [
            _c("div", { staticClass: "payNum" }, [
              _c("span", { staticStyle: { "font-size": "24px" } }, [
                _vm._v("￥"),
              ]),
              _vm._v(" "),
              _c("span", { staticStyle: { "font-size": "24px" } }, [
                _vm._v(_vm._s(parseInt(_vm.payMoney))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "codeBox" }, [
              _c("img", {
                staticStyle: { width: "210px", height: "210px" },
                attrs: { src: _vm.aliPayCode, alt: "" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "payTxt" }, [
              _vm._v("付款详情" + _vm._s(_vm.payMoney)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "payShop" },
              [
                _c("div", { staticClass: "payShopTxt" }, [
                  _vm._v("充值店铺："),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "150px" },
                  attrs: { size: "mini", disabled: "" },
                  model: {
                    value: _vm.payShopName,
                    callback: function ($$v) {
                      _vm.payShopName = $$v
                    },
                    expression: "payShopName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("img", {
              staticStyle: {
                height: "36px",
                "text-align": "left",
                "margin-top": "20px",
                "margin-left": "0",
              },
              attrs: { src: require("@/assets/imgs/alipaybtn.png"), alt: "" },
            }),
            _vm._v(" "),
            _vm._m(2),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.activeIndex === 0
      ? _c("div", { staticClass: "payBox" }, [
          _c("div", { staticClass: "payLeft" }, [
            _c("div", { staticClass: "monthBoxx" }, [
              _c("div", { staticClass: "monthly" }, [
                _c("div", { staticClass: "monthlyTitle" }),
                _vm._v(" "),
                _c("div", { staticClass: "monthlyContent" }, [
                  _c("div", { staticClass: "conContent" }, [
                    _c(
                      "div",
                      [
                        _vm._v("\n                1、联系客服，赠送100积分 "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#3D99FF",
                              "font-size": "13px",
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                _vm.dialogVisible = true
                              },
                            },
                          },
                          [_vm._v(" 立即联系")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                2、如果您在试用期期间订购的其他正式版，需要等试用版时间使用结束后，正式版本的权益会自动到账。\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                3、需要先订购软件，才能进入飞鸟使用软件功能，请注意，积分包月/包年，不包含服务市场的软件订购费用。\n              "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "fk" }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "totalBox" },
              _vm._l(_vm.integralList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "total",
                    class: { totalActive: _vm.totalIndex == index },
                    on: {
                      click: function ($event) {
                        return _vm.handleIntegral(item, index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "totalItem" }, [
                      _c("div", { staticClass: "yuan" }, [
                        _vm._m(3, true),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "totalNum",
                            staticStyle: {
                              color: "#FDBA24",
                              cursor: "pointer",
                              "font-weight": "700",
                              "font-size": "24px",
                            },
                          },
                          [_vm._v(_vm._s(item.denomination * 100))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v("积分"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "totalContent" }, [
                        _c("span", [
                          _vm._v(_vm._s(parseInt(item.denomination))),
                        ]),
                        _vm._v(" "),
                        _vm._m(4, true),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fk" }),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-check iconChoose" }),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            staticStyle: {
              height: "470px",
              width: "1px",
              background: "#EBEBEB",
              margin: "0 10px",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "payRight" }, [
            _c("div", { staticClass: "payNum" }, [
              _c("span", { staticStyle: { "font-size": "24px" } }, [
                _vm._v("￥"),
              ]),
              _vm._v(" "),
              _c("span", { staticStyle: { "font-size": "24px" } }, [
                _vm._v(_vm._s(parseInt(_vm.payMoney))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "codeBox" }, [
              _c("img", {
                staticStyle: { width: "210px", height: "210px" },
                attrs: { src: _vm.aliPayCode, alt: "" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "payTxt" }, [_vm._v("付款详情")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "payShop" },
              [
                _c("div", { staticClass: "payShopTxt" }, [
                  _vm._v("充值店铺："),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "150px" },
                  attrs: { size: "mini", disabled: "" },
                  model: {
                    value: _vm.payShopName,
                    callback: function ($$v) {
                      _vm.payShopName = $$v
                    },
                    expression: "payShopName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("img", {
              staticStyle: {
                height: "36px",
                "text-align": "left",
                "margin-top": "20px",
                "margin-left": "0",
              },
              attrs: { src: require("@/assets/imgs/alipaybtn.png"), alt: "" },
            }),
            _vm._v(" "),
            _vm._m(5),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.activeIndex === 1
      ? _c("div", { staticClass: "redeem" }, [
          _c(
            "div",
            { staticClass: "redeemContent" },
            [
              _c("div", { staticClass: "redeemTitle" }, [
                _vm._v("使用兑换码："),
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: { placeholder: "请输入兑换码" },
                model: {
                  value: _vm.exchangeCode,
                  callback: function ($$v) {
                    _vm.exchangeCode = $$v
                  },
                  expression: "exchangeCode",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: {
                    background: "#212121",
                    border: "none",
                    color: "#fff",
                  },
                  on: { click: _vm.exchangeCodeHandle },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "提示",
              visible: _vm.dialogVisible,
              "append-to-body": "",
              width: "340px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "tag-top",
                staticStyle: { height: "50px" },
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c("el-tag", { attrs: { type: "danger" } }, [
                  _vm._v("微信扫一扫"),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticStyle: { "margin-top": "8px" } },
                  [
                    _c("el-tag", { attrs: { type: "danger" } }, [
                      _vm._v("联系客服领取100使用积分"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("img", {
              staticStyle: { width: "300px", height: "300px" },
              attrs: { src: require("@/assets/imgs/22kefu.png") },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "userImg" }, [
      _c("img", {
        staticStyle: { width: "36px", height: "36px", "border-radius": "50%" },
        attrs: { src: require("@/assets/imgs/boom.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("div", { staticClass: "monthly" }, [
        _c("div", { staticClass: "monthlyTitle" }),
        _vm._v(" "),
        _c("div", { staticClass: "monthlyContent" }, [
          _c("div", { staticClass: "conTitle" }, [
            _c("img", {
              attrs: { src: require("@/assets/imgs/shopping.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("span", { staticStyle: { "margin-left": "5px" } }, [
              _vm._v("购买说明"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "conContent" }, [
            _c("div", [
              _vm._v(
                "\n                1、开通积分包月/包年，每日提供1万积分并优先消耗，在次日同样时间会重置此额度（比如1月25日下午14:00开通，积分清零重置时间为1月26日下午14:00），若当日积分消耗超过1万则会消耗店铺里其他积分。\n              "
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n                2、积分包月/包年的有效期从订购日起计算，与订购应用有效期无关，仅限单店铺使用。\n              "
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n                3、积分包月/包年，可重复购买并叠加有效期。\n              "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { color: "red" } }, [
              _vm._v(
                "\n                4、注意，积分包月，订购后不支持退款，敬请谅解！\n              "
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n                5、本活动为限时限量活动，仅限前"),
              _c("span", { staticStyle: { color: "red" } }, [_vm._v("5000")]),
              _vm._v(
                "名订购，活动下架后不影响已订购店铺的使用。\n              "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fk" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "saoDesc" }, [
      _c("img", {
        staticStyle: { width: "42px" },
        attrs: { src: require("@/assets/imgs/scan-pic.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "saoTxt" }, [
        _c("div", [_vm._v("打开手机支付宝")]),
        _vm._v(" "),
        _c("div", [_vm._v("扫一扫继续支付")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticStyle: { height: "24px", width: "24px" },
        attrs: { src: require("@/assets/imgs/jinbi.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "yuanTxt" }, [
      _c("strong", [_vm._v("元")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "saoDesc" }, [
      _c("img", {
        staticStyle: { width: "42px" },
        attrs: { src: require("@/assets/imgs/scan-pic.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "saoTxt" }, [
        _c("div", [_vm._v("打开手机支付宝")]),
        _vm._v(" "),
        _c("div", [_vm._v("扫一扫继续支付")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }