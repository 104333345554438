"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemSetting = require("@/api/systemSetting");
var _auth = require("@/utils/auth");
var _eventBus = require("./event-bus");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import goodsData from './goods.json'
var _default = exports.default = {
  // props:["parentImgData"],
  data: function data() {
    return {
      activeName: 'first',
      goodsData: {},
      masterImg: [],
      skuImg: [],
      detailImg: [],
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      url: ''
    };
  },
  created: function created() {
    var _this = this;
    _eventBus.eventBus.$on('passMessage', function (message) {
      _this.parentImgData = message;
      console.log('父组件传过来的数据', message);
      _this.goodsData = message;
      _this.masterImg = message.viewImageData;
      _this.detailImg = message.detailGallery;
      _this.skuImg = message.skus;
    });
    // console.log(parentImgData);

    // console.log('主图=====>',goodsData);
    // console.log(this.goodsData.store.initDataObj.goods);
    // this.masterImg = this.goodsData.store.initDataObj.goods.viewImageData;
    // this.detailImg = this.goodsData.store.initDataObj.goods.detailGallery;
    // this.skuImg = this.goodsData.store.initDataObj.goods.skus;
  },
  methods: {
    handleClick: function handleClick() {
      console.log(this.goodsData);
      // this.masterImg = this.goodsData.viewImageData;
      // this.detailImg = this.goodsData.detailGallery;
      // this.skuImg = this.goodsData.skus;
    },
    handleImgChange: function handleImgChange(index, item) {
      console.log(item);
      this.$emit('uploadImg', item);
    },
    beforeUpload: function beforeUpload(file) {
      var _this2 = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var base64 = e.target.result;
        // console.log(base64);
        _this2.croppedImg = base64;
        _this2.handleUploadForm();
      };
      return false; // 阻止默认上传行为
    },
    // 上传
    handleUploadForm: function handleUploadForm() {
      var _this3 = this;
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var param = {
        mallId: this.$parent.mallId,
        image: this.croppedImg
      };
      (0, _systemSetting.uploadImage)(param).then(function (res) {
        console.log(res);
        loading.close();
        // this.url = res.url
        _this3.$emit('uploadImg', res.goodsImageUploadResponse.imageUrl);
        _this3.$message.success('上传成功');
      }).catch(function (res) {
        loading.close();
      });
    }
  }
};