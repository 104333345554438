var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper" }, [
    _c("div", { staticClass: "sidebar" }, [
      _c("img", {
        staticClass: "sidebar-logo-big",
        attrs: { src: require("../assets/imgs/sidebar-logo-big.png") },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-box" },
        _vm._l(_vm.getMenu, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "menu-list",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.navigator(item.component)
                },
              },
            },
            [
              !item.web
                ? _c(
                    "div",
                    {
                      staticClass: "menu-main",
                      style: {
                        padding: item.childList.length == 0 ? "6px 0" : " 0 0",
                        background:
                          item.component == _vm.selectRouter &&
                          item.childList.length == 0
                            ? "#E8F3FF"
                            : "",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src:
                            item.component == _vm.selectRouter
                              ? item.iconPath1
                              : item.iconPath,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "main-title",
                          style: {
                            color:
                              item.component == _vm.selectRouter
                                ? "#0068ff"
                                : "",
                          },
                        },
                        [_vm._v(_vm._s(item.name) + "\n          ")]
                      ),
                      _vm._v(" "),
                      item.childList.length != 0
                        ? _c(
                            "div",
                            { staticClass: "sub-menu" },
                            _vm._l(item.childList, function (i) {
                              return _c(
                                "span",
                                {
                                  key: i.id,
                                  staticClass: "sub-title",
                                  style: {
                                    background:
                                      i.component == _vm.selectRouter
                                        ? "#E8F3FF"
                                        : "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.navigator(i.component)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        color:
                                          i.component == _vm.selectRouter
                                            ? "#0068ff"
                                            : "",
                                      },
                                    },
                                    [_vm._v(_vm._s(i.name))]
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "_main-container" },
      [_c("div", [_c("navbar")], 1), _vm._v(" "), _c("app-main")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "提示",
              visible: _vm.dialogVisible,
              "append-to-body": "",
              width: "340px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "tag-top",
                staticStyle: { height: "50px" },
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c("el-tag", { attrs: { type: "danger" } }, [
                  _vm._v("微信扫一扫"),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticStyle: { "margin-top": "8px" } },
                  [
                    _c("el-tag", { attrs: { type: "danger" } }, [
                      _vm._v("联系客服领取100使用积分"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("img", {
              staticStyle: { width: "300px", height: "300px" },
              attrs: { src: require("@/assets/imgs/22kefu.png") },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }