var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "divBox",
      attrs: { "element-loading-text": "正在生成中" },
    },
    [
      _c("div", { staticClass: "_container" }, [
        _c(
          "div",
          { staticClass: "search-box", staticStyle: { "margin-top": "10px" } },
          [
            _vm.$store.state.user.app_isClient == 1
              ? _c(
                  "div",
                  { staticClass: "search-item" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("选择店铺："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "custom-select",
                        attrs: {
                          "popper-append-to-body": false,
                          placeholder: "请选择",
                          size: "mini",
                        },
                        on: { change: _vm.storeChange },
                        model: {
                          value: _vm.valueSelect,
                          callback: function ($$v) {
                            _vm.valueSelect = $$v
                          },
                          expression: "valueSelect",
                        },
                      },
                      _vm._l(_vm.optionsSelect, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.id,
                            attrs: {
                              disabled: item.onlineStatus == 0,
                              label: item.mallName,
                              value: item.id,
                            },
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.mallName)),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("i", {
                      class: [
                        _vm.rotate ? "el-icon-refresh go" : "el-icon-refresh",
                      ],
                      staticStyle: {
                        margin: "0 5px 0 5px",
                        "font-size": "18px",
                        position: "relative",
                        cursor: "pointer",
                      },
                      on: { click: _vm.getCloudsShopList },
                    }),
                    _vm._v(" "),
                    _vm.getIntegral !== ""
                      ? _c("span", { staticClass: "title" }, [
                          _vm._v(" 积分：" + _vm._s(_vm.getIntegral)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.valueSelect
                      ? _c(
                          "div",
                          {
                            staticClass: "czbtn",
                            on: { click: _vm.handlePayChange },
                          },
                          [
                            _c("span", [_vm._v("立即充值")]),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { width: "21px", height: "21px" },
                              attrs: {
                                alt: "",
                                src: require("@/assets/imgs/jinbi.svg"),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "content-item" }, [
            _c("span", { staticClass: "title" }, [_vm._v("SKU文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-box" },
              [
                _c("el-input", {
                  attrs: {
                    autosize: { minRows: 13, maxRows: 13 },
                    placeholder: "请输入内容",
                    type: "textarea",
                  },
                  model: {
                    value: _vm.sku,
                    callback: function ($$v) {
                      _vm.sku = $$v
                    },
                    expression: "sku",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bottom-box" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.generateSku },
                  },
                  [_vm._v("开始生成\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticStyle: { "margin-left": "10px", color: "red" } },
                  [
                    _vm._v(
                      "预计消耗" +
                        _vm._s(1 * _vm.sku.trim().split("\n").length) +
                        "个积分"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-item" }, [
            _c("span", { staticClass: "title" }, [_vm._v("AI自动生成SKU文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-box", staticStyle: { padding: "5px" } },
              _vm._l(_vm.geneList, function (item) {
                return _c("div", { key: item, staticClass: "item-text" }, [
                  _vm._v("\n            " + _vm._s(item) + "\n          "),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bottom-box" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.copy },
                  },
                  [_vm._v("一键复制\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.openPay,
            "custom-class": "pay-dialog",
            title: "充值",
            width: "1248px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openPay = $event
            },
          },
        },
        [
          _c("payment", {
            ref: "payments",
            attrs: { shopData: _vm.shopDetail },
            on: {
              close: function ($event) {
                _vm.openPay = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }