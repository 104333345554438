var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.show },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("div", { staticClass: "tip-box" }, [
            _c("p", [
              _vm._v(
                "当前裂变商品数 " +
                  _vm._s(_vm.$parent.splitNumber) +
                  "，已选 " +
                  _vm._s(_vm.$parent.splitNumber) +
                  " 张图片"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                " 如已选图片数小于裂变商品数，剩下的裂变商品会取原商品的第一张图片"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "如已选图片数大于裂变商品数，飞鸟会自动截取裂变商品对应数量的图片"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "edit-img-box",
              on: { click: _vm.closePopoverVisible },
            },
            [
              _c("div", { staticClass: "box-left" }, [
                _c(
                  "div",
                  { staticClass: "box-left-item" },
                  [
                    _c("p", { staticClass: "tl" }, [_vm._v("四宫格1：")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: _vm.showHandle,
                              change: function ($event) {
                                return _vm.handleRadioChange($event, 1)
                              },
                            },
                            model: {
                              value: _vm.selectedRadio1,
                              callback: function ($$v) {
                                _vm.selectedRadio1 = $$v
                              },
                              expression: "selectedRadio1",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1", border: "", size: "mini" },
                              },
                              [_vm._v("采用原SKU图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2", border: "", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHandle(1)
                                  },
                                },
                              },
                              [_vm._v("选择主图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "3", border: "", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHandleLoad(1)
                                  },
                                },
                              },
                              [_vm._v("本地上传")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.selectedRadio1 == 2
                          ? _c("span", [
                              _vm._v(
                                "\n              已选择" +
                                  _vm._s(_vm.seNum1) +
                                  "个，"
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#0C8AFF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showImage(1, 1)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n              已选择" +
                                  _vm._s(_vm.seNumCopy1) +
                                  "个，"
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#0C8AFF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showImage(1, 2)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          width: "80",
                          trigger: "click",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        model: {
                          value: _vm.popoverVisible1,
                          callback: function ($$v) {
                            _vm.popoverVisible1 = $$v
                          },
                          expression: "popoverVisible1",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.checkGroupChange($event, 1)
                                  },
                                },
                                model: {
                                  value: _vm.selectedCheckboxes1,
                                  callback: function ($$v) {
                                    _vm.selectedCheckboxes1 = $$v
                                  },
                                  expression: "selectedCheckboxes1",
                                },
                              },
                              _vm._l(_vm.skuListBox, function (item, index) {
                                return _c("el-checkbox", {
                                  attrs: { label: item.imgNum },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      ref: "radioTrigger",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-left-item" },
                  [
                    _c("p", { staticClass: "tl" }, [_vm._v("四宫格2：")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            on: {
                              change: function ($event) {
                                return _vm.handleRadioChange($event, 2)
                              },
                            },
                            model: {
                              value: _vm.selectedRadio2,
                              callback: function ($$v) {
                                _vm.selectedRadio2 = $$v
                              },
                              expression: "selectedRadio2",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1", border: "", size: "mini" },
                              },
                              [_vm._v("采用原SKU图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2", border: "", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHandle(2)
                                  },
                                },
                              },
                              [_vm._v("选择主图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "3", border: "", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHandleLoad(2)
                                  },
                                },
                              },
                              [_vm._v("本地上传")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.selectedRadio2 == 2
                          ? _c("span", [
                              _vm._v(
                                "\n              已选择" +
                                  _vm._s(_vm.seNum2) +
                                  "个，"
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#0C8AFF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showImage(2, 1)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n              已选择" +
                                  _vm._s(_vm.seNumCopy2) +
                                  "个，"
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#0C8AFF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showImage(2, 2)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          width: "80",
                          trigger: "click",
                        },
                        model: {
                          value: _vm.popoverVisible2,
                          callback: function ($$v) {
                            _vm.popoverVisible2 = $$v
                          },
                          expression: "popoverVisible2",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.checkGroupChange($event, 2)
                                  },
                                },
                                model: {
                                  value: _vm.selectedCheckboxes2,
                                  callback: function ($$v) {
                                    _vm.selectedCheckboxes2 = $$v
                                  },
                                  expression: "selectedCheckboxes2",
                                },
                              },
                              _vm._l(_vm.skuListBox, function (item, index) {
                                return _c("el-checkbox", {
                                  attrs: { label: item.imgNum },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      ref: "radioTrigger",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-left-item" },
                  [
                    _c("p", { staticClass: "tl" }, [_vm._v("四宫格3：")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            on: {
                              change: function ($event) {
                                return _vm.handleRadioChange($event, 3)
                              },
                            },
                            model: {
                              value: _vm.selectedRadio3,
                              callback: function ($$v) {
                                _vm.selectedRadio3 = $$v
                              },
                              expression: "selectedRadio3",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1", border: "", size: "mini" },
                              },
                              [_vm._v("采用原SKU图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2", border: "", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHandle(3)
                                  },
                                },
                              },
                              [_vm._v("选择主图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "3", border: "", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHandleLoad(3)
                                  },
                                },
                              },
                              [_vm._v("本地上传")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.selectedRadio3 == 2
                          ? _c("span", [
                              _vm._v(
                                "\n              已选择" +
                                  _vm._s(_vm.seNum3) +
                                  "个，"
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#0C8AFF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showImage(3, 1)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n              已选择" +
                                  _vm._s(_vm.seNumCopy3) +
                                  "个，"
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#0C8AFF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showImage(3, 2)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          width: "80",
                          trigger: "click",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        model: {
                          value: _vm.popoverVisible3,
                          callback: function ($$v) {
                            _vm.popoverVisible3 = $$v
                          },
                          expression: "popoverVisible3",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.checkGroupChange($event, 3)
                                  },
                                },
                                model: {
                                  value: _vm.selectedCheckboxes3,
                                  callback: function ($$v) {
                                    _vm.selectedCheckboxes3 = $$v
                                  },
                                  expression: "selectedCheckboxes3",
                                },
                              },
                              _vm._l(_vm.skuListBox, function (item, index) {
                                return _c("el-checkbox", {
                                  attrs: { label: item.imgNum },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      ref: "radioTrigger",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "box-left-item",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "tl" }, [_vm._v("四宫格4：")]),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        on: {
                          change: function ($event) {
                            return _vm.handleRadioChange($event, 4)
                          },
                        },
                        model: {
                          value: _vm.selectedRadio4,
                          callback: function ($$v) {
                            _vm.selectedRadio4 = $$v
                          },
                          expression: "selectedRadio4",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          { attrs: { label: "1", border: "", size: "mini" } },
                          [_vm._v("采用原SKU图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2", border: "", size: "mini" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.showHandle(4)
                              },
                            },
                          },
                          [_vm._v("选择主图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "3", border: "", size: "mini" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.showHandleLoad(4)
                              },
                            },
                          },
                          [_vm._v("本地上传")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.selectedRadio4 == 2
                      ? _c("span", [
                          _vm._v(
                            "\n              已选择" +
                              _vm._s(_vm.seNum4) +
                              "个，"
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#0C8AFF",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showImage(4, 1)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n              已选择" +
                              _vm._s(_vm.seNumCopy4) +
                              "个，"
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#0C8AFF",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showImage(4, 2)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          width: "80",
                          trigger: "click",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        model: {
                          value: _vm.popoverVisible4,
                          callback: function ($$v) {
                            _vm.popoverVisible4 = $$v
                          },
                          expression: "popoverVisible4",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.checkGroupChange($event, 4)
                                  },
                                },
                                model: {
                                  value: _vm.selectedCheckboxes4,
                                  callback: function ($$v) {
                                    _vm.selectedCheckboxes4 = $$v
                                  },
                                  expression: "selectedCheckboxes4",
                                },
                              },
                              _vm._l(_vm.skuListBox, function (item, index) {
                                return _c("el-checkbox", {
                                  attrs: { label: item.imgNum },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      ref: "radioTrigger",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pre-view-right" }, [
                _c("div", { staticClass: "img-box" }, [
                  _vm.img1
                    ? _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: _vm.img1, alt: "" },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "img-box" }, [
                  _vm.img2
                    ? _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: _vm.img2, alt: "" },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "img-box" }, [
                  _vm.img3
                    ? _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: _vm.img3, alt: "" },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "img-box" }, [
                  _vm.img4
                    ? _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: _vm.img4, alt: "" },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitHandle } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("uploadImages", {
        ref: "uploadImageRef",
        attrs: { show: _vm.uploadImageVisible },
        on: {
          "update:show": function ($event) {
            _vm.uploadImageVisible = $event
          },
        },
      }),
      _vm._v(" "),
      _c("showDialog", {
        ref: "showDialogRef",
        attrs: { show: _vm.isShowDialog },
        on: {
          "update:show": function ($event) {
            _vm.isShowDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }