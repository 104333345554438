var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { backgroundColor: _vm.variables.menuBackground } },
    [
      _vm.showLogo ? _c("logo") : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        {
          staticStyle: { height: "calc( 100vh - 80px)" },
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBackground,
                "text-color": _vm.variables.menuColor,
                "unique-opened": true,
                "active-text-color": _vm.variables.menuActiveText,
                mode: "vertical",
              },
            },
            [
              _vm._l(_vm.sidebarRouters, function (route) {
                return _c("sidebar-item", {
                  key: route.url,
                  attrs: { item: route, "base-path": route.url },
                })
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  this.$store.state.user.app_isClient == 0
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "2-1" },
                          on: { click: _vm.download },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "18px",
                              "margin-right": "6px",
                            },
                            attrs: {
                              src: "https://91feiniao.oss-cn-hangzhou.aliyuncs.com/file/feiNiaoProductManagement/icon/3c2ff6585dbb135ce92386f789d8d57.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                position: "relative",
                                left: "-5px",
                              },
                            },
                            [_vm._v("下载客户端")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "2-09" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = true
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "18px", "margin-right": "6px" },
                        attrs: {
                          src: "https://91feiniao.oss-cn-hangzhou.aliyuncs.com/file/feiNiaoProductManagement/icon/101eb47313afd3dbf46aecdedd60663.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { position: "relative", left: "-5px" } },
                        [_vm._v("免费领积分")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "2-11" },
                      on: { click: _vm.downloadOpen },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "18px", "margin-right": "6px" },
                        attrs: {
                          src: "https://91feiniao.oss-cn-hangzhou.aliyuncs.com/file/feiNiaoProductManagement/icon/13fd56c574b77ef406c27762e1d69a7.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { position: "relative", left: "-5px" } },
                        [_vm._v("使用教程")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialog" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "提示",
                visible: _vm.dialogVisible,
                "append-to-body": "",
                width: "340px",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tag-top",
                  staticStyle: { height: "50px" },
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("el-tag", { attrs: { type: "danger" } }, [
                    _vm._v("微信扫一扫"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticStyle: { "margin-top": "8px" } },
                    [
                      _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v("联系客服领取100使用积分"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("img", {
                staticStyle: { width: "300px", height: "300px" },
                attrs: { src: require("@/assets/imgs/22kefu.png") },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }