"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCropper = require("vue-cropper");
var _addImg = _interopRequireDefault(require("@/views/goodsManage/copyAllIndex/components/addImg.vue"));
var _systemSetting = require("@/api/systemSetting");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['skulist', 'seIndex', 'mallId'],
  components: {
    VueCropper: _vueCropper.VueCropper,
    addImg: _addImg.default
  },
  watch: {
    skulist: function skulist(newVal, oldVal) {
      this.imgList = newVal;
    }
  },
  created: function created() {
    this.imgList = this.skulist;
  },
  data: function data() {
    return {
      fullWdith: false,
      model: false,
      modelSrc: '',
      previews: {},
      openClip: false,
      option: {
        img: '',
        //裁剪图片的地址 默认空，可选值url地址, base64, blob
        outputSize: 0.8,
        //裁剪生成图片的质量 默认1 可选0.1 ~ 1
        outputType: 'image/jpg,image/png',
        //裁剪生成图片的格式 jpg (jpg 需要传入jpeg) 可选：jpeg, png, webp
        info: true,
        //裁剪框的大小信息 默认：true
        canScale: false,
        //图片是否允许滚轮缩放
        autoCrop: true,
        //是否默认生成截图框
        // autoCropWidth: 80%, //默认生成截图框宽度 默认：80% 可选：0-max
        //autoCropHeight: 80%, //默认生成截图框高度 默认：80% 可选：0-max
        fixed: true,
        //是否开启截图框宽高固定比例
        fixedNumber: [1, 1],
        //截图框的宽高比例 [1, 1]-[ 宽度 , 高度 ]
        full: true,
        //是否输出原图比例的截图
        fixedBox: false,
        //固定截图框大小 默认不允许改变 可选：false
        //canMove: true,//上传图片是否可以移动
        canMoveBox: false,
        //截图框能否拖动
        original: false,
        //上传图片按照原始比例渲染
        canterBox: false,
        //截图框是否被限制在图片里面
        //high: true,//是否按照设备的dpr 输出等比例图片
        infoTrue: true,
        //true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        // maxImgSize: 2000 //限制图片最大宽度和高度 可选：0 ~ max
        //enlarge: 1, //图片根据截图框输出比例倍数 默认1 可选：0 ~ max(建议不要太大不然会卡死的呢)
        //mode: contain //contain , cover, 100px, 100% auto
        centerBox: true // 截图框是否被限制在图片里面
      },
      cropIndex: '',
      //裁剪图片的下彪
      croppedImg: null,
      previewStyle2: {},
      imgList: [{
        url: 'https://img1.baidu.com/it/u=2076256699,2973570530&fm=253&fmt=auto?w=500&h=500',
        id: 0
      }, {
        url: 'https://img2.baidu.com/it/u=210313716,1622428801&fm=253&fmt=auto&app=120&f=JPEG?w=510&h=500',
        id: 1
      }, {
        url: 'https://img0.baidu.com/it/u=2784521324,4041515553&fm=253&fmt=auto&app=138&f=JPEG?w=475&h=475',
        id: 2
      }, {
        url: 'https://img2.baidu.com/it/u=2747863348,1919593876&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=800',
        id: 3
      }, {
        url: 'https://img2.baidu.com/it/u=739112412,328524343&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=800',
        id: 4
      }],
      draggedIndex: null,
      iconList: [{
        id: 0,
        icon: 'el-icon-upload2',
        title: '上传图片'
      }, {
        id: 1,
        icon: 'el-icon-download',
        title: '下载图片'
      }, {
        id: 2,
        icon: 'el-icon-scissors',
        title: '裁剪图片'
      }, {
        id: 3,
        icon: 'el-icon-view',
        title: '预览图片'
      }, {
        id: 4,
        icon: 'el-icon-delete',
        title: '删除图片'
      }],
      picVisible: false,
      //预览图片
      preImg: '' //预览内容
    };
  },
  methods: {
    openCloasp: function openCloasp(i) {
      this.fullWdith = !this.fullWdith;
      if (i == 1) {
        this.$parent.goodsSkuList[this.seIndex].fullWdithParent = false;
      } else {
        this.$parent.goodsSkuList[this.seIndex].fullWdithParent = true;
      }
    },
    // 实时预览函数--右侧展示样式
    realTime: function realTime(data) {
      var previews = data;
      var h = 0.6; //高度固定，不然右侧预览会变形
      // let w = 0.2;

      this.previewStyle2 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        margin: '0',
        zoom: h
      };
      this.previews = data;
    },
    imgLoad: function imgLoad(msg) {
      console.log(msg);
    },
    cropMoving: function cropMoving(data) {
      this.option.cropData = data;
    },
    loadImage: function loadImage(imgUrl) {
      var _this = this;
      // 假设这里获取网络图片的URL并加载
      // const imgUrl = 'https://example.com/your - image.jpg';
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        _this.img = img;
      };
      img.src = imgUrl;
    },
    getCropData: function getCropData() {
      var _this2 = this;
      // 获取最终裁剪数据
      // 获取裁剪后的图片
      this.$refs.cropper.getCropBlob(function (blob) {
        var reader = new FileReader();
        reader.onload = function (e) {
          _this2.croppedImg = e.target.result;
          console.log(_this2.croppedImg);
          _this2.handleUploadForm();
        };
        reader.readAsDataURL(blob);
      });
    },
    // 上传,图片
    handleUploadForm: function handleUploadForm() {
      var _this3 = this;
      // const base64Data = this.croppedImg;
      // const formData = new FormData();
      // formData.append('multipart', base64Data);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var param = {
        mallId: this.$parent.mallId || this.mallId,
        image: this.croppedImg
      };
      console.log(param, 'param---imghandle');
      (0, _systemSetting.uploadImage)(param).then(function (res) {
        loading.close();
        // this.url = res.url
        // this.$emit('uploadImg', this.url)
        _this3.$message.success('上传成功');
        _this3.imgList[_this3.cropIndex] = res.goodsImageUploadResponse.imageUrl;
        _this3.$forceUpdate();
        _this3.openClip = false;
      }).catch(function (res) {
        loading.close();
      });
    },
    handleFileUpload: function handleFileUpload(e, v) {
      var _this4 = this;
      console.log(e, v, 667767);
      var file = event.target.files[0];
      var reader = new FileReader();
      var img = new Image();
      reader.onload = function (e) {
        img.src = e.target.result;
        img.onload = function () {
          var width = img.width;
          var height = img.height;
          var ratio = width / height;
          if ((ratio == 1 || ratio == 3 / 4) && width > 480 && height > 480) {
            _this4.croppedImg = e.target.result;
            _this4.handleUploadForm();
          } else {
            _this4.$message.error('图片尺寸不符合要求，请重新上传');
            return;
          }
        };
      };
      reader.onerror = function (error) {
        console.error('Error reading file:', error);
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    },
    /**
     * 下载图片到本地
     */
    downImg: function downImg(index) {
      var _this5 = this;
      var img = new Image();
      img.src = this.imgList[index];
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext('2d');
      img.crossOrigin = 'Anonymous'; // 设置跨域请求
      img.onload = function () {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        var dataURL = canvas.toDataURL('image/png'); // 转换为PNG格式的Data URL
        var link = document.createElement('a');
        link.href = dataURL;
        link.download = 'downloaded_image.png'; // 设置下载的文件名

        // 触发下载
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        _this5.$message.success('下载成功，请查看下载文件夹');
      };
    },
    /**
     * 删除图片操作
     * @param i
     */
    delImg: function delImg(index) {
      if (this.imgList.length == 1) {
        this.$message.error('至少保留一张图片');
        return;
      }
      this.imgList.splice(index, 1);
    },
    /**
     * 预览操作
     * @param i
     * @param index
     */
    preImgHandle: function preImgHandle(index) {
      this.picVisible = true;
      this.preImg = this.imgList[index];
    },
    // 图片的操作功能
    optionHandle: function optionHandle(i, index) {
      if (i == 0) {
        this.$refs.fileInput[0].click();
        this.cropIndex = index;
      }
      if (i == 1) {
        this.downImg(index);
      }
      if (i == 2) {
        this.openClip = true;
        this.option.img = this.imgList[index];
        this.loadImage(this.imgList[index]);
        this.cropIndex = index;
      }
      if (i == 3) {
        this.preImgHandle(index);
      }
      if (i == 4) {
        this.delImg(index);
      }
    },
    handleDragStart: function handleDragStart(index, event) {
      if (this.draggedIndex !== null) {
        return;
      }
      this.draggedIndex = index;
      event.dataTransfer.setData('text/plain', index);
    },
    handleDrop: function handleDrop(dropIndex, event) {
      var draggedIndexStr = event.dataTransfer.getData('text/plain');
      var draggedIndex1 = parseInt(draggedIndexStr, 10);
      if (this.draggedIndex !== null && this.draggedIndex !== dropIndex) {
        var _ref = [this.imgList[draggedIndex1], this.imgList[dropIndex]],
          draggedItem = _ref[0],
          dropItem = _ref[1];
        this.$set(this.imgList, draggedIndex1, dropItem);
        this.$set(this.imgList, dropIndex, draggedItem);
      }
      this.draggedIndex = null;
    }
  }
};