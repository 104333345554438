"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMallCopyApi = addMallCopyApi;
exports.checkDataList = checkDataList;
exports.createCopySettingApi = createCopySettingApi;
exports.createCopyTaskAllTestApi = createCopyTaskAllTestApi;
exports.createExportData = createExportData;
exports.createTaskCopyApi = createTaskCopyApi;
exports.createTaskCopyLjApi = createTaskCopyLjApi;
exports.delStore1Api = delStore1Api;
exports.deleteExportList = deleteExportList;
exports.deleteGoodsApi = deleteGoodsApi;
exports.editsplitGoodsPuHuoApi = editsplitGoodsPuHuoApi;
exports.exportList = exportList;
exports.getAuthListApi = getAuthListApi;
exports.getTaskCopyApi = getTaskCopyApi;
exports.getWhitePicApi = getWhitePicApi;
exports.goodDetails = goodDetails;
exports.mallCopyListApi = mallCopyListApi;
exports.pddGoodsCatsGet = pddGoodsCatsGet;
exports.postTemplateApi = postTemplateApi;
exports.setGoodsStatusApi = setGoodsStatusApi;
exports.splitGoodsApi = splitGoodsApi;
exports.splitGoodsDetailApi = splitGoodsDetailApi;
exports.splitGoodsPuHuoApi = splitGoodsPuHuoApi;
exports.startSplitApi = startSplitApi;
exports.upDataGoodsApi = upDataGoodsApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * AI商品运营，更新商品
 */
function upDataGoodsApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/updateGoods',
    method: 'POST',
    data: data
  });
}

/**
 * AI商品运营，删除功能
 */
function deleteGoodsApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/deleteGoods',
    method: 'POST',
    data: data
  });
}

/**
 * AI商品运营，下架商品
 */
function setGoodsStatusApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/setGoodsStatus',
    method: 'POST',
    data: data
  });
}

/**
 * 检测店铺状态--应用市场
 */
function checkDataList(data) {
  return (0, _request.default)({
    url: '/admin/pdd/store/storeList',
    method: 'POST',
    data: data
  });
}

/**
 * 裂变--保存复制配置
 */
function createCopySettingApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/createCopySetting',
    method: 'POST',
    data: data
  });
}

/**
 * 裂变--
 */
function startSplitApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/add',
    method: 'POST',
    data: data
  });
}

/**
 * 机会商品裂变编辑铺货接口
 * @param data
 * @returns {*}
 */
function editsplitGoodsPuHuoApi(data) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/userMall/editDraftGoods',
    method: 'POST',
    data: data
  });
}

/**
 * AI商品运营--获取运费模板
 */
function postTemplateApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/logisticsTemplate',
    method: 'POST',
    data: data
  });
}

/**
 * 裂变---铺货设置
 */
function splitGoodsPuHuoApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/fissionAdd',
    method: 'POST',
    data: data
  });
}

/**
 * 裂变---商品列表商品列表
 */
function splitGoodsApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/goodsList',
    method: 'POST',
    data: data
  });
}
/**
 * 裂变---商品详情接口
 */
function splitGoodsDetailApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/detail',
    method: 'POST',
    data: data
  });
}

/**
 * 授权店铺--删除店铺
 */
function delStore1Api(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/deleteMallCopy',
    method: 'POST',
    data: data
  });
}

/**
 * 授权店铺--全店复制
 */
function createCopyTaskAllTestApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/createCopyTaskAll',
    method: 'POST',
    data: data
  });
}

/**
 * 授权店铺--任务列表
 */
function getAuthListApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/copyRecordList',
    method: 'POST',
    data: data
  });
}

/**
 * 授权--创建 复制
 */
function createTaskCopyApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/createCopyTask',
    method: 'POST',
    data: data
  });
}

/**
 * 获授权店铺复制添加授权码
 */
function addMallCopyApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/addMallCopy',
    method: 'POST',
    data: data
  });
}

/**
 * 授权店铺查询授权码
 */
function mallCopyListApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/mallCopyList',
    method: 'POST',
    data: data
  });
}

/**
 * 获取白底图
 */
function getWhitePicApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/pddGoodsMaterialQuery',
    method: 'POST',
    data: data
  });
}

/**
 * 授权--立即复制
 */
function createTaskCopyLjApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/createCopyTask_LJ',
    method: 'POST',
    data: data
  });
}

/**
 * 查询复制任务
 */
function getTaskCopyApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/copy/copyRecordDetailList',
    method: 'POST',
    data: data
  });
}

/**
*商品导出
*/
function createExportData(data) {
  return (0, _request.default)({
    url: '/admin/pdd/export/createExportData',
    method: 'POST',
    data: data
  });
}
function goodDetails(data) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/admin/goodDetails',
    method: 'POST',
    data: data
  });
}

/**
* 商品导出列表
*/
function exportList(data) {
  return (0, _request.default)({
    url: '/admin/pdd/export/exportList',
    method: 'POST',
    data: data
  });
}
/**
* 导出记录删除
*/
function deleteExportList(data) {
  return (0, _request.default)({
    url: '/admin/pdd/export/deleteExportList',
    method: 'POST',
    data: data
  });
}

/**
*商品分类
*/
function pddGoodsCatsGet(data) {
  return (0, _request.default)({
    url: '/admin/pdd/classification/official/pddGoodsCatsGet',
    method: 'POST',
    data: data
  });
}