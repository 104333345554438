var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-cloumn" },
    [
      _c(
        "div",
        { staticClass: "img-box" },
        _vm._l(_vm.imgList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "img-item",
              attrs: { draggable: "true" },
              on: {
                dragstart: function ($event) {
                  return _vm.handleDragStart(index, $event)
                },
                dragover: function ($event) {
                  $event.preventDefault()
                },
                drop: function ($event) {
                  return _vm.handleDrop(index, $event)
                },
              },
            },
            [
              _c("img", { staticClass: "img-sku", attrs: { src: item } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "options-box" },
                _vm._l(_vm.iconList, function (item, i) {
                  return _c(
                    "el-tooltip",
                    {
                      key: item.id,
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: item.title,
                        placement: "bottom",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.optionHandle(i, index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            class: item.icon,
                            staticStyle: { "font-size": "14px", color: "#fff" },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            ref: "fileInput",
                            refInFor: true,
                            staticStyle: { display: "none" },
                            attrs: { type: "file" },
                            on: { change: _vm.handleFileUpload },
                          }),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览图片",
            visible: _vm.picVisible,
            width: "550px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.picVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "500px", height: "500px" },
            attrs: { src: _vm.preImg, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-container",
          attrs: {
            width: "800px",
            title: "图片裁剪",
            visible: _vm.openClip,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openClip = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "body" }, [
              _c("div", { staticClass: "cropp-content container" }, [
                _c("div", { staticClass: "bg-white" }, [
                  _c(
                    "div",
                    { staticClass: "cropper-cont" },
                    [
                      _c("vue-cropper", {
                        ref: "cropper",
                        attrs: {
                          img: _vm.option.img,
                          outputSize: _vm.option.size,
                          outputType: _vm.option.outputType,
                          info: true,
                          full: _vm.option.full,
                          canMove: _vm.option.canMove,
                          canMoveBox: _vm.option.canMoveBox,
                          fixedBox: _vm.option.fixedBox,
                          original: _vm.option.original,
                          autoCrop: _vm.option.autoCrop,
                          autoCropWidth: _vm.option.autoCropWidth,
                          autoCropHeight: _vm.option.autoCropHeight,
                          centerBox: _vm.option.centerBox,
                          high: _vm.option.high,
                          infoTrue: _vm.option.infoTrue,
                          enlarge: _vm.option.enlarge,
                        },
                        on: {
                          realTime: _vm.realTime,
                          imgLoad: _vm.imgLoad,
                          cropMoving: _vm.cropMoving,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "pre mt8" }, [
                    _c("section", { staticClass: "pre-item" }, [
                      _c("div", { style: _vm.previewStyle2 }, [
                        _c("div", { style: _vm.previews.div }, [
                          _c("img", {
                            style: _vm.previews.img,
                            attrs: { src: _vm.previews.url },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "20px",
                      "text-align": "right",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.openClip = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.getCropData },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }